import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/ClassroomFileSharing.vue'
import FilePlayer from '../views/FilePlayer.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },

  {
    path: '/FilePlayer',
    name: 'FilePlayer',
    component: FilePlayer

  }


  // StoreCourseInfo

  // CourseContent.vue

  // 

  // FilesView 

  // SHOWPPT
  // TeaEditPart
]

const router = new VueRouter({
  // 
  // mode: 'history',

  mode: 'hash',

  base: process.env.BASE_URL,
  routes
})



export default router
