import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import router from './router'
import store from './store'; // 引入 Vuex store
import Contextmenu from "vue-contextmenujs"
import * as THREE from 'three';
import WebGL from '../src/js/WebGL.js';
import router from './router'

Vue.use(Contextmenu);
Vue.prototype.$app
Vue.prototype.$auth
Vue.prototype.$db
//是否支持webgl
Vue.prototype.$WebGL_available = false
Vue.prototype.$EditKey
Vue.prototype.$ShareCloudFiles_inSchool
Vue.prototype.$USERINFO


//图片查看器
// import Viewer from 'v-viewer'
// import 'viewerjs/dist/viewer.css'

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
Vue.use(VueViewer)



// Vue.prototype.$PartInfo_partThreeData=null
//base64
import '../src/js/base64';
Vue.prototype.$MyBase64 = window.Base64





//是否支持webgl
Vue.prototype.$WebGL_available = false

Vue.prototype.$renderer
//不支持则不用生成渲染器
if (WebGL.isWebGL2Available()) {

  // console.log('Vue.prototype.$renderer',Vue.prototype.$renderer)
  //将webgl渲染器放到全局里面，避免new出多个渲染器导致页面空白
  Vue.prototype.$renderer = new THREE.WebGLRenderer({ antialias: true });
} else {
  console.error("不支持3D")
}

Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,  // 注入路由
  store,

  render: h => h(App),
}).$mount('#app')
