<template>
  <div id="app" @touchstart="handleTouchStart" @touchmove="handleTouchMove" @contextmenu.prevent
    @dragover.prevent="preventDefaultDrop" @drop="handleDrop">

    <!-- <ClassroomFile /> -->

   
    <keep-alive>
      <router-view />
    </keep-alive>
    <!-- <FilePlayer /> -->


  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

// import ClassroomFile from './views/ClassroomFileSharing.vue'
import { ShareCloudFiles_inSchool } from "../src/js/ShareCloudFiles_inSchool.js";

// import * as THREE from 'three';
import cloudbase from "@cloudbase/js-sdk";
import WebGL from '../src/js/WebGL.js';
// import FilePlayer from '@/components/FilePlayer.vue'



export default {
  name: 'App',
  components: {
    // ClassroomFile,
    // FilePlayer
  },
  async mounted() {


    window.addEventListener('resize', this.handleResize);


    this.$root.$app = cloudbase.init({ env: "cloudbase-baas-5g5r8gfv6e64cb31" });

    this.$root.$auth = this.$root.$app.auth({
      persistence: "local"

    });

    console.log('this.$root.$auth2222', this.$root.$auth)

    this.$root.$db = this.$root.$app.database()

    //匿名登录才能操作云函数
    await this.$root.$auth.signInAnonymously()






    this.$store.dispatch('changeWindowSize', {
      VueViewWidth: window.innerWidth,
      VueViewHeight: window.innerHeight
    });






    function isWebGL1Supported() {
      var canvas = document.createElement('canvas');
      return !!(window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')));
    }

    console.log('WebGL1 supported支持:', isWebGL1Supported());

    function isWebGL2Supported() {
      var canvas = document.createElement('canvas');
      return !!window.WebGL2RenderingContext && !!canvas.getContext('webgl2');
    }

    console.log('WebGL2 supported支持:', isWebGL2Supported());

    this.checkWebGLSupport()


    // this.$root.$ShareCloudFiles_inSchool = new ShareCloudFiles_inSchool(user_info)
    this.$root.$ShareCloudFiles_inSchool = new ShareCloudFiles_inSchool()

    // user_info
    //校区内文件分享
    this.$root.$ShareCloudFiles_inSchool.init(this.$root.$app, this.$root.$auth)
    console.log('实例化文件处理工具', this.$root.$ShareCloudFiles_inSchool)

  },
  beforeDestroy() {

    console.log('销毁点击退出登录')
    this.$root.$auth.signOut()
    // 移除监听器
    window.removeEventListener('resize', this.handleResize);
    // ipcRenderer.removeAllListeners('window-maximized');

  },

  methods: {
    checkWebGLSupport() {
      console.log('WebGL3333', WebGL)
      if (WebGL.isWebGL2Available()) {
        console.log('WebGL 2 is availablewwww!');

        this.$root.WebGL_available = true
        // 在这里初始化 Three.js 场景、相机、渲染器等
        // 例如：
        // const scene = new THREE.Scene();
        // const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        // const renderer = new THREE.WebGLRenderer();
        // renderer.setSize(window.innerWidth, window.innerHeight);
        // document.body.appendChild(renderer.domElement);

        // 定义动画函数
        // function animate() {
        //   requestAnimationFrame(animate);
        //   // 在这里更新场景和渲染
        //   // renderer.render(scene, camera);
        // }
        // 开始动画循环
        // animate();
      } else {
        const warning = WebGL.getWebGL2ErrorMessage();
        console.error('WebGL 2 www不支持!', warning);
        this.$root.WebGL_available = false
        this.$alert('设备老旧，不支持3D搭建图，但支持在线文档', '不兼容', {
          confirmButtonText: '确定',
          callback: action => {
            this.$message({
              type: 'info',
              message: `action: ${action}`
            });
          }
        })
        // const container = document.getElementById('container');
        // container.appendChild(warning);
      }
    },

    handleResize() {

      this.$store.dispatch('changeWindowSize', {
        VueViewWidth: window.innerWidth,
        VueViewHeight: window.innerHeight
      });

    },


    handleTouchStart(event) {
      // 检查是否为多指触摸
      if (event.touches.length > 1) {

        console.log('多指头')
        event.preventDefault();
      } else {
        console.log('1指头')

      }
    },

    handleTouchMove(event) {
      // 检查是否为多指触摸
      if (event.touches.length > 1) {
        console.log('多指头')

        event.preventDefault();
      } else {
        console.log('1指头')

      }
    },

    //阻止默认的拖拽事件
    preventDefaultDrop(event) {

      event.preventDefault();

      console.log('组件文件拖拽')
      //阻止文件放置到页面的事件
      // event.preventDefault();
    },
    handleDrop(event) {

      event.preventDefault();

    },

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}

/* 去除白色边 */
body {
  margin: 0;
  padding: 0;
}
</style>
