<template>
    <!-- show-part-info-view -->
    <!-- :style="'width:{{window_.innerWidth}}; height: width:{{window_.innerHeight}}'" -->
    <div @touchstart="updataRenderer" @mousemove="updataRenderer"
        :style="{ width: viewWidth + 'px', height: viewHeight + 'px' }"
        :class="showPartInfo ? 'show-part-info-view' : 'hide-part-info-view'">

        <div
            style="width: 100%;height: 100%;overflow: hide;display: flex;justify-content: center;align-items: center;flex-direction: column;">

            <div style="width: 100%;display: flex;align-items: center;justify-content: center;padding: 5px;">
                <div style="display: flex;flex-direction: row;align-items: center;max-height: 100%;font-size: medium;">
                    <button id="textButton" style="margin-right: 5px;" @click="changeLengthUnit">切换单位</button>
                    <button id="textButton" style="margin-right: 5px;" @click="rotatePart">上下旋转</button>
                    <button id="textButton" @click="dialogFormVisible_setRuler = true">量尺校准</button>
                </div>
            </div>

            <div style="width: 100%;flex:1;display: flex;justify-content: center;align-items: center;">



                <div :style="{ width: viewWidth * 0.9 + 'px', height: viewHeight * 0.9 + 'px' }" id="infoView"
                    style="width: 100%; height: 100%; background-color:#dfebed; position: relative;">
                    <!-- <h1>这是零件信息视图</h1> -->
                    <div id="threepart" style="width: 100%;height:100%;"></div>

                    <div style="position:absolute;top: 0;left: 0;"><el-button @click="closeView" type="text"
                            style="padding: 0px;font-size:xx-large;margin: 10px;z-index: 40;"><i
                                class="el-icon-close"></i></el-button></div>

                    <!-- 零件信息 -->
                    <div
                        style="position:absolute;top: 10px;right: 10px;display: flex;flex-direction: column;align-items: flex-end;">

                        <div v-if="partname"> {{ partname }} :零件 </div>
                        <!-- this.Length_unit = 'lego_small'
            } else if (this.Length_unit == 'lego_small') {
                this.Length_unit = 'mm' -->
                        <div v-if="Modelsize && Length_unit == 'lego_small'">{{ Modelsize }}（乐高）:零件尺寸</div>
                        <div v-if="Modelsize && Length_unit == 'mm'">{{ Modelsize }}（毫米）:零件尺寸</div>
                        <!-- <div>
                    <el-button @click="rotatePart" style="font-size:xx-large;" type="text"><i
                            class="el-icon-d-caret"></i></el-button>
                </div> -->

                    </div>


                    <!-- 乐高刻度 -->
                    <div v-if="Modelsize && Length_unit == 'lego_small'"
                        style="font-size:x-large ;position:absolute;bottom: 10px;left: 10px;display: flex;flex-direction: row;pointer-events: none;">
                        <!-- <div style="margin-right: 10px;">比例尺</div> -->
                        <div
                            style="display: flex;flex-direction: row;align-items: center;height: auto;font-size: small;">
                            <!-- 前面的竖着的刻度 -->
                            <div style="height: 100%;display: flex;flex-direction: row;align-items:flex-start">
                                <div style="height:10px;border-left: 1px solid black;"></div>
                            </div>
                            <!-- 中间刻度 -->
                            <div style="height: 100%;display: flex;flex-direction: row;align-items: center"
                                v-for="( item, index ) in Number_2 " :key="index">
                                <div :style="'width:' + bili_1mm * 8 + 'px' + ';height: 100%;'">
                                    <div
                                        style="height:10px;width: 100%;border-left: none;border-bottom: 1px solid black; border-right: 1px solid black;box-sizing: border-box; border-top: none;">
                                    </div>
                                    <div style="display: flex;">
                                        <div style="transform: translateX(-50%);">{{ index }}</div>
                                    </div>
                                </div>
                            </div>
                            <!-- 最大值 -->
                            <div
                                style="height: 100%;display: flex;flex-direction: row;align-items:flex-start;height: 100%;">
                                <div style="transform: translateX(-50%);margin-top:10px ;">{{ Number_2 }}</div>
                            </div>
                        </div>
                    </div>
                    <!-- 毫米长度 -->
                    <div v-if="Modelsize && Length_unit == 'mm'"
                        style="font-size:x-large ;position:absolute;bottom: 10px;left: 10px;display: flex;flex-direction: row;pointer-events: none;">
                        <!-- <div style="margin-right: 10px;">比例尺</div> -->
                        <div
                            style="display: flex;flex-direction: row;align-items: center;height: auto;font-size: xx-small;">
                            <!-- 前面的竖着的刻度 -->
                            <div style="height: 100%;display: flex;flex-direction: row;align-items:flex-start">
                                <div style="height:10px;border-left: 1px solid black;"></div>
                            </div>
                            <!-- 中间刻度 -->
                            <div style="height: 100%;display: flex;flex-direction: row;align-items: center"
                                v-for="( item, index ) in Number_mm_rulor " :key="index">
                                <div :style="'width:' + bili_1mm * 10 + 'px' + ';height: 100%;'">
                                    <!-- 前5毫米 -->
                                    <div
                                        style="height:10px;width: 100%;border-left: none;border-bottom: 1px solid black;
                                border-right: 1px solid black;box-sizing: border-box; border-top: none;display: flex;align-items: flex-end">
                                        <div v-for="( item, index ) in 4 " :key="item + '-' + index"
                                            style="width:10%;border-right: 1px solid #000000;box-sizing: border-box;height: 50%;">
                                        </div>
                                        <!-- 第5毫米  -->
                                        <div
                                            style="width:10%;border-right: 1px solid #000000;box-sizing: border-box;height: 80%;">
                                        </div>
                                        <div v-for="( item, index ) in 4 " :key="item + '--' + index"
                                            style="width:10%;border-right: 1px solid #000000;box-sizing: border-box;height: 50%;">
                                        </div>

                                    </div>
                                    <div style="display: flex;">
                                        <div style="transform: translateX(-50%);">{{ index * 10 }}</div>
                                    </div>
                                </div>
                            </div>
                            <!-- 最大值 -->
                            <div
                                style="height: 100%;display: flex;flex-direction: row;align-items:flex-start;height: 100%;">
                                <div style="transform: translateX(-50%);margin-top:10px ;">{{ Number_mm_rulor * 10 }}
                                </div>
                            </div>
                        </div>
                    </div>


                </div>


            </div>



        </div>


        <div v-if="dialogFormVisible_setRuler"
            style="position:absolute;top: 0px;left: 0px;width: 100vw;height: 100vh;display: flex;align-items: center;justify-content: center;background-color: rgba(69, 69, 69, 0.685);backdrop-filter: blur(10px);">
            <!-- <el-form :model="form">
                <el-form-item label="活动名称" :label-width="formLabelWidth">
                    <el-input v-model="form.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="活动区域" :label-width="formLabelWidth">
                    <el-select v-model="form.region" placeholder="请选择活动区域">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
            </el-form> -->
            <!-- slot="footer" class="dialog-footer" -->
            <!-- <div v-if="dialogFormVisible_setRuler == true" @mouseup="dialogFormVisible_setRuler = false" style="background-color: #f5f5f5;padding: 100px;">
                <el-button @click="dialogFormVisible_setRuler = false">取 消</el-button>
                <el-button type="primary" @click="dialogFormVisible_setRuler = false">确 定</el-button>
            </div> -->
            <!-- <el-dialog title="提示" :visible.sync="dialogFormVisible_setRuler" width="30%" center>
                <span>需要注意的是内容是默认不居中的</span>
                <span  >
                    <el-button @click="dialogFormVisible_setRuler = false">取 消</el-button>
                    <el-button type="primary" @click="dialogFormVisible_setRuler = false">确 定</el-button>
                </span>
            </el-dialog> -->

            <!-- 长度单位校准 -->
            <div
                style="display:flex;flex-direction:column;padding-left: 50px;padding-right:50px ;padding-bottom: 10px;padding-top: 10px;background-color: aliceblue;position: relative;">
                <span style="font-weight:1000 ;margin-bottom: 30px;">请测量出红色线段的长度</span>
                <span>
                    <div id="rulor_show" style="border-bottom: 5px rgb(255, 79, 3) solid;margin-bottom: 30px;"></div>
                    <div style="display: flex; flex-direction: row; align-items: center;margin-bottom: 30px;">
                        <div style="border-bottom: 5px slategray solid; margin: 0 10px;"></div>
                        <span style="white-space: nowrap; margin-right: 5px;">长度：</span>
                        <el-input type="number" size="small" v-model="rulorShowWidth_input" placeholder="毫米单位"
                            style="flex-grow: 1; margin: 0 5px;"></el-input>
                        <span style="white-space: nowrap;">mm（毫米）</span>
                    </div>

                    <el-button @click="dialogFormVisible_setRuler = false">取 消</el-button>
                    <el-button type="primary" @click="setRulerData">确 定</el-button>
                </span>

                <el-button style="position:absolute;top: 5px;right:5px ;padding: 0px;z-index: 50;" type="text"
                    @click="dialogFormVisible_setRuler = false">
                    <i style="font-size: 30px;" class="el-icon-close"></i>
                </el-button>
            </div>

        </div>
        <!-- <el-dialog title="提示" :visible.sync="dialogFormVisible_setRuler" width="30%" :before-close="handleClose"> -->

        <!-- <el-dialog title="提示" :visible.sync="dialogFormVisible_setRuler" width="30%" style="z-index: auto;">
            <span>这是一段信息</span>
            <span >
                <el-button @click="dialogFormVisible_setRuler = false">取 消</el-button>
                <el-button type="primary" @click="dialogFormVisible_setRuler = false">确 定</el-button>
            </span>
        </el-dialog> -->





    </div>
</template>

<script>
//   import tcb from "@cloudbase/js-sdk"

import * as THREE from 'three';

// import { GUI } from 'three/addons/libs/lil-gui.module.min.js';

// import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
// import { RoomEnvironment } from 'three/addons/environments/RoomEnvironment.js';

import { OrbitControls } from '../../src/js/OrbitControls.js';
// import { TransformControls } from "../../src/js/TransformControls.js";

import { RoomEnvironment } from '../../src/js/RoomEnvironment.js';
import { TextGeometry } from '../../src/js/TextGeometry.js';
import { FontLoader } from '../../src/js/FontLoader.js';
import myFontJson from '../../src/js/helvetiker_regular.typeface.json';

// import { EventBus } from '@/eventBus.js';


// import { LDrawLoader } from 'three/addons/loaders/LDrawLoader.js';
// import { LDrawUtils } from 'three/addons/utils/LDrawUtils.js';

let container
let camera, scene, renderer, controls

var x_Mesh, y_Mesh, z_Mesh
var txt_x_box_length, txt_y_box_length, txt_z_box_length
var modex_box3
var showmodex_box3

var x_Mesh_positionArr, y_Mesh_positionArr, z_Mesh_positionArr
var BOXhelper


var container_infoView


var UserLastOperateTimeStamp = Date.now()


var EnableRender = true

// var threeView_width,threeView_height

// let width = container.offsetWidth;
//             let height = container.offsetHeight;
// var loader1
// ,y_Mesh,z_Mesh


var model_
// let model

// PartInfo_partThreeData
export default {
    data() {
        return {
            // app: null,
            Number_2: 5,
            Number_mm_rulor: 5,

            //长度单位
            Length_unit: 'lego_small',
            //尺寸
            Modelsize: null,
            partname: null,
            dialogFormVisible_setRuler: false,
            //比例尺设置时显示的示例线段的宽度毫米
            rulorShowWidth_input: null,

            //1mm对应所少像素（css）
            bili_1mm: 10,

            // window_: window,

            viewWidth: null,
            viewHeight: null,
            showPartInfo: false,




        };

    },

    // props: {
    //     ChooseObj3D: Object
    // },

    // watch: {
    //     ChooseObj3D: {
    //         immediate: true, // 如果你想在组件挂载时立即触发处理函数，可以设置 immediate 为 true

    //         handler(newValue, oldValue) {

    //             console.log('oldValue', oldValue)

    //             var that = this

    //             if (model_) {
    //                 model_.removeFromParent()
    //             }

    //             //延迟一下，否则会导致大模型，比如ev3主控未完成就计算相机，从而导致摄像机没对准ev3主控
    //             setTimeout(() => {
    //                 that.loadModel(newValue)
    //             }, 10);

    //         }
    //     },
    // },


    async mounted() {
        // var that = this

        // EventBus.$on('modelData', this.handleModelData);

        this.$parent.$on('messageToPartInfoView', this.handleParentMessage);




        container_infoView = document.querySelector("#infoView")

        console.log('container_infoView444443333', container_infoView)

        this.init();
        this.animate();



        var data = localStorage.getItem('bili_1mm');

        var value = JSON.parse(data); // 如果data是对象或数组，需要解析回来

        if (!data) {

            // localStorage.setItem('bili_1mm', 1);

            value = 3

        }

        console.log('缓存数据获取回来:', value)
        // var container_infoView = document.querySelector("#infoView")
        this.bili_1mm = value * 1



        console.log('this.Number_2', this.Number_2)
        console.log('this.Number_mm_rulor', this.Number_mm_rulor)


        // 监听事件总线上的 window-resized 事件
        // this.$eventBus.$on('window-resized', this.windowResize());

        window.addEventListener('resize', this.windowResize);

        this.viewWidth = window.innerWidth

        this.viewHeight = window.innerHeight


        this.timeLooper()


        // this.windowResize()


        // localStorage.setItem('bili_1mm', JSON.stringify(this.bili_1mm));


        // model = new THREE.
        // const geometry = new THREE.BoxGeometry(10, 10, 10);
        // const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
        // const cube = new THREE.Mesh(geometry, material);
        // scene.add(cube);

        // setTimeout(() => {
        //     console.log('子组建的3d对象', that.ChooseObj3D)
        //     scene.add(that.ChooseObj3D)
        // }, 6000);

    },

    beforeDestroy() {
        // 确保组件销毁时移除事件监听，避免内存泄漏
        window.removeEventListener('resize', this.windowResize);

        // 移除事件监听器
        if (this.$parent && this.handleParentMessage) {
            this.$parent.$off('messageToBottom', this.handleParentMessage);
        }

        // PartInfo_partThreeData

        controls.dispose()

        console.log('零件信息关闭前的场景', scene, scene.children.length)
        this.clearScene(scene)

        console.log('清除后的场景', scene.children.length)

        container = null
        camera = null
        scene = null
        renderer = null
        controls = null
        x_Mesh = null
        y_Mesh = null
        z_Mesh = null
        txt_x_box_length = null
        txt_y_box_length = null
        txt_z_box_length = null
        // modex_box3 = null
        showmodex_box3 = null
        x_Mesh_positionArr = null
        y_Mesh_positionArr = null
        z_Mesh_positionArr = null

        BOXhelper = null

        container_infoView = null

        // 清理事件监听，防止内存泄漏
        // EventBus.$off('modelData', this.handleModelData);

        // this.$eventBus.$off('window-resized', this.windowResize());
    },



    methods: {

        init() {

            var that = this
            // container = document.createElement('div');
            // document.body.appendChild(container);

            container = document.querySelector("#threepart");

            console.log('零件信息容器信息', container)

            camera = new THREE.OrthographicCamera(-window.innerWidth * 0.9 / 2, window.innerWidth * 0.9 / 2, window.innerHeight * 0.9 / 2, -window.innerHeight * 0.9 / 2, 1, 50000);

            // camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 10000);

            camera.position.set(5000, 5000, 5000);

            //

            // renderer = new THREE.WebGLRenderer({ antialias: true });

            renderer = this.$root.$renderer

            renderer.setPixelRatio(window.devicePixelRatio);

            // var
            container_infoView = document.querySelector("#infoView")

            renderer.setSize(window.innerWidth * 0.9, window.innerHeight * 0.9);

            // console.log('this.$root.$VueViewWidth',this.VueViewWidth,this.$root.$VueViewHeight)

            // this.Number_2 = Math.floor((container_infoView.offsetWidth - 20) / (this.bili_1mm * 8))
            // this.Number_mm_rulor = Math.floor((container_infoView.offsetWidth - 20) / (this.bili_1mm) / 10)
            // container_infoView.offsetWidth, container_infoView.offsetHeight

            // renderer.setSize(window.innerWidth,window.innerHeight);

            renderer.toneMapping = THREE.ACESFilmicToneMapping;
            container.appendChild(renderer.domElement);

            // scene
            const pmremGenerator = new THREE.PMREMGenerator(renderer);

            scene = new THREE.Scene();
            scene.background = new THREE.Color(0xdeebed);
            scene.environment = pmremGenerator.fromScene(new RoomEnvironment(renderer)).texture;

            controls = new OrbitControls(camera, renderer.domElement);

            controls.enableZoom = false

            controls.enablePan = false

            controls.addEventListener('change', function () {

                //没有模型则不用弄
                if (!model_) {

                    return

                }
                // 每当相机移动、旋转或缩放时，这里的代码都会被执行
                console.log('相机位置改变了！');
                that.x_Mesh_UpdatePosition()
                x_Mesh.lookAt(camera.position)

                that.y_Mesh_UpdatePosition()
                y_Mesh.lookAt(camera.position)

                that.z_Mesh_UpdatePosition()
                z_Mesh.lookAt(camera.position)

                // that.updateXYZMeshSize()

            });
            // x_Mesh
            controls.enableDamping = false;


            const mainLight2 = new THREE.AmbientLight(0xffffff, 1.8)

            // const directionalLight = new THREE.DirectionalLight(0xffffff, 4);
            // scene.add(directionalLight);
            // const light = new THREE.PointLight(0xff0000, 4, 100);
            // light.position.set(50, 50, 50);
            // scene.add(light);
            // const mainLight = new PointLight( 0xffffff, 1, 100 )

            scene.add(mainLight2)

            mainLight2.position.set(0.418, 16.199, 0.300);

            // camera.lookAt(new THREE.Vector3(0, 0, 0));
            // camera.updateMatrix()
            // camera.updateProjectionMatrix();
            // controls.target.copy(new THREE.Vector3(0, 0, 0));
            // controls.update();

        },

        animate() {

            console.log('222 EnableRender', EnableRender)



            //只有在零件信息查看不启用的时候才渲染
            // if (!Object.keys(this.ChooseObj3D).length === 0) {

            if (this.showPartInfo && model_ && EnableRender) {

                controls.update();

                this.render();

                console.log('零件信息洁面刷新')
            }

            requestAnimationFrame(this.animate);

            // }
            // this.animate()


        },

        render() {


            renderer.render(scene, camera);

            // if (x_Mesh) {
            //     camera.updateMatrix()
            //     // x_Mesh.lookAt(camera.position)

            // }
        },

        updataRenderer() {


            UserLastOperateTimeStamp = Date.now()
            EnableRender = true
            console.log('鼠标移动111222', UserLastOperateTimeStamp)

        },

        timeLooper() {

            const that = this

            setTimeout(() => {

                console.log('每秒钟工作一次', EnableRender)
                console.log('Date.now() - UserLastOperateTimeStamp', Date.now() - UserLastOperateTimeStamp)

                // if (EnableRender) {


                if (Date.now() - UserLastOperateTimeStamp > 1000) {

                    console.log('停止工作')

                    EnableRender = false


                }
                // }

                that.timeLooper()

            }, 1000);

        },


        clearScene(scene) {
            // if (!scene) {

            //     return

            // }

            console.log('清除场景执行')
            // 遍历场景中的所有对象
            scene.traverse(child => {
                // 处理网格对象和其他可卸载的对象
                if (child instanceof THREE.Mesh || child instanceof THREE.Light || child instanceof THREE.Camera) {
                    // 如果对象有材质，遍历材质并处置
                    if (child.material instanceof THREE.Material) {
                        if (Array.isArray(child.material)) { // 处理数组类型的材质（比如多材质对象）
                            child.material.forEach(function (material) {
                                material.dispose();
                            });
                        } else {
                            child.material.dispose();
                        }
                    }
                }

                // 如果对象是几何体，处置几何体
                if (child.geometry) {
                    child.geometry.dispose();
                }
                // if (child == undefined) {

                //     console.log('undefined', child.parent)

                // } else {
                //     if (child.isMesh || child.isLight || child.isCamera) {
                //         child.removeFromParent()
                //     }

                //     if (child.isMesh) {
                //         if (Array.isArray(child.material)) { // 处理数组类型的材质（比如多材质对象）
                //             child.material.forEach(function (material) {
                //                 material.dispose();
                //             });
                //         } else {
                //             child.material.dispose();
                //         }
                //     }
                // }



                // 从场景中移除对象
                // scene.remove(child);
            });

            // 清除场景本身可能引用的其他资源，根据实际使用情况可能需要定制化处理
        },

        // 触发一个名为"childEvent"的事件，并传递消息"Hello, Parent Component!"
        //接收父级事件
        handleParentMessage(message) {

            var that = this
            // ,targetStep: 

            if (message.type == 'import3DData') {

                if (model_) {
                    model_.removeFromParent()
                    model_ = null
                }



                this.showPartInfo = true

                //避免查看零件时看到一个大零件
                setTimeout(() => {
                    // model_ = 'tempmodel'

                    // this.loadModel(message.data)
                    var newGroup = new THREE.Group()
                    newGroup.copy(message.data)
                    this.loadModel(newGroup)
                    // message.data = null
                    // this.loadModel(message.data.clone())
                    // message.data = null
                }, 20);




                //延迟一下，否则会导致大模型，比如ev3主控未完成就计算相机，从而导致摄像机没对准ev3主控
                setTimeout(() => {

                    that.clearObject(message.data)
                    // message.data = null
                }, 200);

            } else if (message.type == "closePartInfo") {

                this.closeView()



            }

            console.log('父组件发送的消息222223333:', message);
            // console.log('oldValue', oldValue)




            // message.data = 
        },

        clearObject(obj) {
            for (let key in obj) {
                if (typeof obj[key] === 'object' && obj[key] !== null && !(obj[key] instanceof Array)) {
                    // 如果属性是对象，则递归调用清空函数
                    this.clearObject(obj[key]);
                } else {
                    // 否则，直接删除属性
                    delete obj[key];
                }
            }
        },


        // this.$emit('image-hover', e.item);

        closeView() {
            this.showPartInfo = false
            console.log('关闭零件信息视图')
            
            if (model_) model_.removeFromParent()

            console.log('scene3333', scene)

            //移除场景内多余的东西，只保留灯光
            var remove_arr = []

            for (let i = 0; i < scene.children.length; i++) {

                if (scene.children[i].isGroup || scene.children[i].isLine) {

                    remove_arr.push(scene.children[i])
                }

            }

            for (let i = 0; i < remove_arr.length; i++) {
                remove_arr[i].removeFromParent()
            }

            remove_arr = null



            renderer.render(scene, camera)

            // this.removeModel(model_)
            this.clearObject(model_)
            model_ = null
            // this.showPartInfo = false
            this.$emit('clickCloseInfoView');
        },

        //移除所有模型数据，避免多次加载模型内存溢出

        removeModel(model) {

            // modelRotateTweenArr = []
            var meshArr = []
            if (model) {

                model.traverse(c => {
                    if (c.isMesh) {
                        meshArr.push(c)
                    }
                    if (c.isLine) {
                        meshArr.push(c)
                    }
                })

            }

            for (let i = 0; i < meshArr.length; i++) {
                this.removeModelAndReleaseMemory(meshArr[i])
            }

            model = null

        },


        removeModelAndReleaseMemory(model) {
            // 1. 首先，从场景中移除模型
            if (model.parent !== null) {
                model.parent.remove(model);
            }

            // 2. 清理模型自身的属性，尤其是Geometry和Material
            if (model.geometry) {
                model.geometry.dispose(); // 清理几何体资源
                model.geometry = null;
            }

            if (model.material) {
                // 如果是单一材质
                if (model.material.dispose) {
                    model.material.dispose(); // 清理材质资源
                    model.material = null;
                }
                // 如果是材质数组（如MeshFaceMaterial）
                else if (Array.isArray(model.material)) {
                    model.material.forEach(material => {
                        if (material.dispose) {
                            material.dispose();
                        }
                    });
                    model.material.length = 0;
                    model.material = null;
                }
            }

            // 3. 对于使用了纹理的模型，还需要释放纹理资源
            if (model.material && model.material.map) {
                model.material.map.dispose(); // 清理纹理资源
                model.material.map = null;
            }

            // 如果模型还有其他特殊资源，也需要相应地清理它们
            // ...

            // 最后，设置模型引用为null，帮助垃圾回收机制工作
            model = null;
        },


        windowResize() {

            // console.log('时间总线上的事件PartInfoView')

            this.viewWidth = window.innerWidth

            this.viewHeight = window.innerHeight

            //没有渲染则不会有反应
            // if (Object.keys(this.ChooseObj3D).length == 0) { return }

            // this.window_ = window


            // const width = window.innerWidth * 1;
            // const height = window.innerHeight * 1;


            // this.MPDdivWidth = width

            // setOrthCameraData()

            //模型界面关闭后不做计算
            if (!model_) { return }

            //设置正交投影相机的参数，让物体最大化显示
            this.setOrthCameraData()

            // camera.aspect = width / height;

            // camera.updateProjectionMatrix();

            // 更新渲染器的大小
            // renderer.setSize(width, height);
            // renderer.setSize(window.innerWidth * 0.9, window.innerHeight * 0.9);
            // var container_infoView = document.querySelector("#infoView")

            renderer.setSize(window.innerWidth * 0.9, window.innerHeight * 0.9);

            renderer.toneMapping = THREE.ACESFilmicToneMapping;

            container.appendChild(renderer.domElement);

            //必须渲染一次，否则画面会出问题
            renderer.render(scene, camera);

            this.Number_2 = Math.floor((container_infoView.offsetWidth - 20) / (this.bili_1mm * 8))

            this.Number_mm_rulor = Math.floor((container_infoView.offsetWidth - 20) / (this.bili_1mm) / 10)


        },



        //加载模型
        loadModel(newValue) {



            // 这里 newValue 是新的值，oldValue 是旧的值
            // console.log('variableA 发生了变化，新值:', newValue, oldValue);

            if (model_) {
                model_.removeFromParent()
                model_ = null
            }

            // if (this.showPartInfo) {

            //     return

            // }

            if (x_Mesh) x_Mesh.removeFromParent()
            if (y_Mesh) y_Mesh.removeFromParent()
            if (z_Mesh) z_Mesh.removeFromParent()
            if (BOXhelper) BOXhelper.removeFromParent()


            // var container_infoView = document.querySelector("#infoView")
            //留20的出色线
            this.Number_2 = Math.floor((container_infoView.offsetWidth - 20) / (this.bili_1mm * 8))
            this.Number_mm_rulor = Math.floor((container_infoView.offsetWidth - 20) / (this.bili_1mm) / 10)


            console.log('this.Number_2', this.Number_2, this.Number_mm_rulor)



            // console.log('子组建的3d对象', this.ChooseObj3D, newValue)
            // 将JSON格式的数据转换回Object3D对象
            // var loader = new THREE.ObjectLoader();
            // loader.parse(newValue, function (obj) {
            //     // obj即为转换后的Object3D对象
            //     console.log(obj);
            //     model_ = obj
            //     console.log('clickObj444',model_)
            // });
            model_ = newValue


            // model_ = newValue
            scene.add(model_)




            this.partname = model_.name
            //更新世界矩阵测试
            // newValue.traverse(c => {
            //     console.log('子代克隆属性',c)
            //     c.matrix = c.matrix.clone()
            //     c.matrixWorld = c.matrixWorld.clone()
            //     c.quaternion = c.quaternion.clone()
            //     c.rotation = c.rotation.clone()

            //     // c.updateWorldMatrix(false, false);
            //     // try {

            //     //     c.updateWorldMatrix(false, false);

            //     // }
            //     // catch (err) {

            //     //     console.log('err333',err)

            //     //     console.error('c222222错误', c)
            //     //     // document.getElementById("demo").innerHTML = err.message;
            //     // }

            //     // if (!c.matrixWorld) {

            //     //     console.log('c222222', c)

            //     // }

            // })
            // newValue.updateWorldMatrix (true,true)
            // model_.rotation.set(Math.PI, 0, 0)
            // model_.quaternion.set(0, 0, 0, 1);

            const quaternion_new = new THREE.Quaternion();

            quaternion_new.setFromAxisAngle(new THREE.Vector3(1, 0, 0), Math.PI);

            model_.quaternion.copy(quaternion_new)

            model_.position.set(0, 0, 0)
            model_.visible = true


            console.log('scene33', scene)

            this.setModelBox3()

            //重新计算视图尺寸
            this.windowResize()

            this.setLengthUnit(this.Length_unit)
            // this.setLengthUnit()

            this.addXYZMesh()

            this.x_Mesh_UpdatePosition()
            x_Mesh.lookAt(camera.position)

            this.y_Mesh_UpdatePosition()
            y_Mesh.lookAt(camera.position)

            this.z_Mesh_UpdatePosition()
            z_Mesh.lookAt(camera.position)

            const lookAt_ = new THREE.Vector3()
            modex_box3.getCenter(lookAt_)
            console.log('lookAt_', lookAt_)
            //以最佳的大小将模型显示在屏幕中间
            camera.lookAt(lookAt_);
            // camera.lookAt(cameraLookAt);
            console.log('container444', controls, controls.target)
            controls.target.copy(lookAt_);
            controls.update();

            this.setOrthCameraData()

            renderer.render(scene, camera);


        },

        //旋转模型，上下颠倒
        rotatePart() {
            model_.updateMatrix()
            console.log('model_', model_)
            console.log('加前', model_.rotation.x)
            // model_.rotation.x = model_.rotation.x+Math.PI
            model_.rotateX(Math.PI)
            // model_.setRotationFromAxisAngle (new THREE.Vector3( 1, 0, 0 ), Math.PI)
            // model_.quaternion.setFromAxisAngle( new THREE.Vector3( 1, 0, 0 ), Math.PI);
            console.log('加后', model_.rotation.x)


            if (x_Mesh) x_Mesh.removeFromParent()
            if (y_Mesh) y_Mesh.removeFromParent()
            if (z_Mesh) z_Mesh.removeFromParent()
            if (BOXhelper) BOXhelper.removeFromParent()





            // console.log('子组建的3d对象', this.ChooseObj3D, newValue)
            // 将JSON格式的数据转换回Object3D对象
            // var loader = new THREE.ObjectLoader();
            // loader.parse(newValue, function (obj) {
            //     // obj即为转换后的Object3D对象
            //     console.log(obj);
            //     model_ = obj
            // });

            // model_ = newValue
            // scene.add(model_)
            //更新世界矩阵测试
            // newValue.traverse(c => {
            //     console.log('子代克隆属性',c)
            //     c.matrix = c.matrix.clone()
            //     c.matrixWorld = c.matrixWorld.clone()
            //     c.quaternion = c.quaternion.clone()
            //     c.rotation = c.rotation.clone()

            //     // c.updateWorldMatrix(false, false);
            //     // try {

            //     //     c.updateWorldMatrix(false, false);

            //     // }
            //     // catch (err) {

            //     //     console.log('err333',err)

            //     //     console.error('c222222错误', c)
            //     //     // document.getElementById("demo").innerHTML = err.message;
            //     // }

            //     // if (!c.matrixWorld) {

            //     //     console.log('c222222', c)

            //     // }

            // })
            // newValue.updateWorldMatrix (true,true)
            // model_.rotation.set(Math.PI, 0, 0)
            model_.position.set(0, 0, 0)

            console.log('scene33', scene)


            this.setModelBox3()

            this.setLengthUnit(this.Length_unit)

            this.addXYZMesh()

            this.x_Mesh_UpdatePosition()
            x_Mesh.lookAt(camera.position)

            this.y_Mesh_UpdatePosition()
            y_Mesh.lookAt(camera.position)

            this.z_Mesh_UpdatePosition()
            z_Mesh.lookAt(camera.position)


            const lookAt_ = new THREE.Vector3()
            modex_box3.getCenter(lookAt_)
            console.log('lookAt_', lookAt_)
            //以最佳的大小将模型显示在屏幕中间
            camera.lookAt(lookAt_);
            // camera.lookAt(cameraLookAt);
            console.log('container444', controls, controls.target)
            controls.target.copy(lookAt_);
            controls.update();


            // model_.rotation.set(-Math.PI, 0, 0)
            // model_.rotation._x += Math.PI/2
        },


        //为模型计算包围盒
        setModelBox3() {




            const box = new THREE.Box3();
            model_.updateMatrixWorld();

            model_.traverse((c) => {
                if (c.isMesh) {
                    box.expandByObject(c);
                }
            });

            console.log('模型包围盒3333', box)

            modex_box3 = box


            var setData = this.setXYZMeshSizeScaleAndSafeDistance()
            var safeDistance_ = setData.safeDistance
            console.log('setData.safeDistance', setData.safeDistance)
            showmodex_box3 = modex_box3.clone()



            showmodex_box3.max.x += safeDistance_
            showmodex_box3.max.y += safeDistance_
            showmodex_box3.max.z += safeDistance_

            showmodex_box3.min.x -= safeDistance_
            showmodex_box3.min.y -= safeDistance_
            showmodex_box3.min.z -= safeDistance_

            x_Mesh_positionArr = this.set_XMesh_PositionArr()
            y_Mesh_positionArr = this.set_YMesh_PositionArr()
            z_Mesh_positionArr = this.set_ZMesh_PositionArr()


            var Box3_show = box.clone()
            //稍稍扩大点来显示包围框
            Box3_show.max.x += 0.005
            Box3_show.max.y += 0.005
            Box3_show.max.z += 0.005

            Box3_show.min.x -= 0.005
            Box3_show.min.y -= 0.005
            Box3_show.min.z -= 0.005
            BOXhelper = new THREE.Box3Helper(Box3_show, 0x9b59b6);
            scene.add(BOXhelper);


            console.log('xyz_Mesh_positionArr', x_Mesh_positionArr, y_Mesh_positionArr, z_Mesh_positionArr)


            console.log('modex_box33333', modex_box3)
            console.log('showmodex_box333333', showmodex_box3)


            // const axesHelper = new THREE.AxesHelper(55);
            // scene.add(axesHelper);
            // box.expandByObject()
            // box.setFromCenterAndSize(new THREE.Vector3(1, 1, 1), new THREE.Vector3(2, 1, 3));




            //求模型尺寸
            // console.log('box4444', box)

            // txt_x_box_length = this.formatNumber(Math.abs(box.max.x - box.min.x)) / 20
            // txt_y_box_length = this.formatNumber(Math.abs(box.max.y - box.min.y)) / 20
            // txt_z_box_length = this.formatNumber(Math.abs(box.max.z - box.min.z)) / 20

            // console.log('尺寸是222', txt_x_box_length, txt_y_box_length, txt_z_box_length)

            // this.Modelsize = txt_x_box_length + 'X' + txt_y_box_length + 'X' + txt_z_box_length

            // // var SIZEtext = txt_x_box_length + 'X' + txt_y_box_length + 'X' + txt_z_box_length

            // console.log('SIZEtext', this.Modelsize)

        },




        //加上xyz字母mesh
        addXYZMesh() {

            var setData = this.setXYZMeshSizeScaleAndSafeDistance()

            console.log('setData.scaleXYZ', setData.scaleXYZ)

            var scale_ = setData.scaleXYZ
            // var safeDistance_ = setData.safeDistance

            //xxxxxxxxxxx
            var x_Mesh_ = this.getMeshByText('' + txt_x_box_length, 0xe74c3c).clone()
            x_Mesh = new THREE.Group()
            scene.add(x_Mesh);

            x_Mesh.scale.set(scale_, scale_, scale_)

            x_Mesh.add(x_Mesh_)
            var BOX_X = new THREE.Box3()
            BOX_X.setFromBufferAttribute(x_Mesh_.geometry.attributes.position)
            // BOX_X.expandByObject(x_Mesh_)
            x_Mesh_.position.set(-1 * ((BOX_X.max.x - BOX_X.min.x) / 2), 0, 0)



            //yyyyyyyyyyyyyyy
            // x_Mesh
            var y_Mesh_ = this.getMeshByText('' + txt_y_box_length, 0x16a085).clone()
            y_Mesh = new THREE.Group()
            scene.add(y_Mesh);
            y_Mesh.scale.set(scale_, scale_, scale_)

            // const axesHelper = new THREE.AxesHelper(15);
            // y_Mesh.add(axesHelper);

            y_Mesh.add(y_Mesh_)

            var BOX_Y = new THREE.Box3()
            BOX_Y.setFromBufferAttribute(y_Mesh_.geometry.attributes.position)
            // BOX_Y.expandByObject(y_Mesh_)
            y_Mesh_.position.set(0, -1 * ((BOX_Y.max.x - BOX_Y.min.x) / 2), 0)
            y_Mesh_.rotation.z = Math.PI / 2




            //zzzzzzzzzzzz
            var z_Mesh_ = this.getMeshByText('' + txt_z_box_length, 0x2980b9).clone()
            z_Mesh = new THREE.Group()
            scene.add(z_Mesh);
            z_Mesh.scale.set(scale_, scale_, scale_)

            z_Mesh.add(z_Mesh_)
            var BOX_Z = new THREE.Box3()
            // BOX_Z.expandByObject(z_Mesh_)
            BOX_Z.setFromBufferAttribute(z_Mesh_.geometry.attributes.position)
            z_Mesh_.position.set(-1 * ((BOX_Z.max.x - BOX_Z.min.x) / 2), 0, 0)



            console.log('textMesh1', x_Mesh)

            // x_Mesh.position.y = 100


            console.log(y_Mesh, z_Mesh)

            // y_Mesh.position.y = 100


            // z_Mesh.position.y = 100


        },


        //对小数处理
        formatNumber(num) {
            // 判断是否为数字
            // if (isNaN(num)) {
            //     return '请输入有效数字';
            // }

            // 取两位小数
            let formattedNum = parseFloat(num).toFixed(2);

            console.log('formattedNum ', formattedNum, num)

            // 判断小数部分是否为整数
            if (formattedNum.endsWith('.00')) {
                // console.log(formatNumber(123.456)) // 输出 123.46
                // console.log(formatNumber(123.00)) // 输出 123
                // console.log(formatNumber('abc')) // 输出 请输入有效数字
                return parseInt(formattedNum)
            }

            return parseInt(formattedNum)

        },


        //创建4个坐标系
        //在其他函数里面控制这些坐标系显示哪个就行了
        // CreateHelperCoordinateSystem() {

        //     //假设长度是100
        //     //假设坐标点1是(50,50,50)
        //     var GROUP1 = new THREE.Group()
        //     const geometry = new THREE.CylinderGeometry(5, 5, 20, 32);
        //     const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
        //     const cylinder = new THREE.Mesh(geometry, material);
        //     GROUP1.add(cylinder)

        //     scene.add(cylinder)

        // },

        getMeshByText(text_input, color_) {
            console.log('color_', color_)
            var _Mesh
            // LocalLoad( path, onLoad)
            console.log('myFontJsoneeee', myFontJson)
            // var myFontJson = 
            var loader1 = new FontLoader()
            loader1.LocalLoad(myFontJson, function (response) {
                console.log('fonteeee3333', response)
                var font = response;
                // refreshText();
                var materialXYZ = new THREE.MeshPhongMaterial({ color: color_ }) // front
                console.log('materials222', materialXYZ)
                // new THREE.MeshPhongMaterial({ color: 0xffffff }) // side
                var textGeo = new TextGeometry(text_input, {
                    font,
                    size: 100,
                    depth: 1,
                    curveSegments: 4,
                    bevelThickness: 2,
                    bevelSize: 1.5,
                    bevelEnabled: true
                });
                _Mesh = new THREE.Mesh(textGeo, materialXYZ);
            });

            console.log('_Mesheee', _Mesh)

            return _Mesh

        },


        //计算x长度的显示坐标，四个
        set_XMesh_PositionArr() {
            console.log('modex_box33333', modex_box3)
            console.log('showmodex_box3eee', showmodex_box3)

            var x1 = showmodex_box3.max.x - ((showmodex_box3.max.x - showmodex_box3.min.x) / 2)
            var y1 = showmodex_box3.max.y - ((showmodex_box3.max.y - showmodex_box3.min.y) / 2)
            var z1 = showmodex_box3.max.z - ((showmodex_box3.max.z - showmodex_box3.min.z) / 2)
            var X_positionArr = []
            var x_position1 = new THREE.Vector3(x1, y1 + (showmodex_box3.max.y - showmodex_box3.min.y) / 2, z1 + (showmodex_box3.max.z - showmodex_box3.min.z) / 2)
            X_positionArr.push(x_position1)
            var x_position2 = new THREE.Vector3(x1, y1 + (showmodex_box3.max.y - showmodex_box3.min.y) / 2, z1 - (showmodex_box3.max.z - showmodex_box3.min.z) / 2)
            X_positionArr.push(x_position2)
            var x_position3 = new THREE.Vector3(x1, y1 - (showmodex_box3.max.y - showmodex_box3.min.y) / 2, z1 + (showmodex_box3.max.z - showmodex_box3.min.z) / 2)
            X_positionArr.push(x_position3)
            var x_position4 = new THREE.Vector3(x1, y1 - (showmodex_box3.max.y - showmodex_box3.min.y) / 2, z1 - (showmodex_box3.max.z - showmodex_box3.min.z) / 2)
            X_positionArr.push(x_position4)

            console.log('xMeshPositionArr', X_positionArr)

            return X_positionArr

            // var x_position1 = new THREE.Vector3()


            // var X = 0
            // var Y_1 = 
            // //4个坐标
            // var 

            //看看哪个离摄像头更近



        },

        //计算y长度的显示坐标，四个
        set_YMesh_PositionArr() {
            console.log('modex_box33333', modex_box3)
            console.log('showmodex_box3eee', showmodex_box3)

            var x1 = showmodex_box3.max.x - ((showmodex_box3.max.x - showmodex_box3.min.x) / 2)
            var y1 = showmodex_box3.max.y - ((showmodex_box3.max.y - showmodex_box3.min.y) / 2)
            var z1 = showmodex_box3.max.z - ((showmodex_box3.max.z - showmodex_box3.min.z) / 2)
            var Y_positionArr = []
            var y_position1 = new THREE.Vector3(x1 + (showmodex_box3.max.x - showmodex_box3.min.x) / 2, y1, z1 + (showmodex_box3.max.z - showmodex_box3.min.z) / 2)
            Y_positionArr.push(y_position1)
            var y_position2 = new THREE.Vector3(x1 + (showmodex_box3.max.x - showmodex_box3.min.x) / 2, y1, z1 - (showmodex_box3.max.z - showmodex_box3.min.z) / 2)
            Y_positionArr.push(y_position2)
            var y_position3 = new THREE.Vector3(x1 - (showmodex_box3.max.x - showmodex_box3.min.x) / 2, y1, z1 + (showmodex_box3.max.z - showmodex_box3.min.z) / 2)
            Y_positionArr.push(y_position3)
            var y_position4 = new THREE.Vector3(x1 - (showmodex_box3.max.x - showmodex_box3.min.x) / 2, y1, z1 - (showmodex_box3.max.z - showmodex_box3.min.z) / 2)
            Y_positionArr.push(y_position4)

            console.log('xMeshPositionArr', Y_positionArr)

            return Y_positionArr

            // var x_position1 = new THREE.Vector3()


            // var X = 0
            // var Y_1 = 
            // //4个坐标
            // var 

            //看看哪个离摄像头更近



        },

        //计算z长度的显示坐标，四个
        set_ZMesh_PositionArr() {
            console.log('modex_box33333', modex_box3)
            console.log('showmodex_box3eee', showmodex_box3)

            var x1 = showmodex_box3.max.x - ((showmodex_box3.max.x - showmodex_box3.min.x) / 2)
            var y1 = showmodex_box3.max.y - ((showmodex_box3.max.y - showmodex_box3.min.y) / 2)
            var z1 = showmodex_box3.max.z - ((showmodex_box3.max.z - showmodex_box3.min.z) / 2)
            var Z_positionArr = []
            var Z_position1 = new THREE.Vector3(x1 + (showmodex_box3.max.x - showmodex_box3.min.x) / 2, y1 + (showmodex_box3.max.y - showmodex_box3.min.y) / 2, z1)
            Z_positionArr.push(Z_position1)
            var Z_position2 = new THREE.Vector3(x1 + (showmodex_box3.max.x - showmodex_box3.min.x) / 2, y1 - (showmodex_box3.max.y - showmodex_box3.min.y) / 2, z1)
            Z_positionArr.push(Z_position2)
            var Z_position3 = new THREE.Vector3(x1 - (showmodex_box3.max.x - showmodex_box3.min.x) / 2, y1 + (showmodex_box3.max.y - showmodex_box3.min.y) / 2, z1)
            Z_positionArr.push(Z_position3)
            var Z_position4 = new THREE.Vector3(x1 - (showmodex_box3.max.x - showmodex_box3.min.x) / 2, y1 - (showmodex_box3.max.y - showmodex_box3.min.y) / 2, z1)
            Z_positionArr.push(Z_position4)

            console.log('xMeshPositionArr', Z_positionArr)

            return Z_positionArr

            // var x_position1 = new THREE.Vector3()


            // var X = 0
            // var Y_1 = 
            // //4个坐标
            // var 

            //看看哪个离摄像头更近

        },
        //更新xmesh的显示位置
        x_Mesh_UpdatePosition() {

            if (!x_Mesh_positionArr) return

            //看看哪个坐标离相近最近
            var nearstDistance = Infinity
            var nearstPosition
            for (let i = 0; i < x_Mesh_positionArr.length; i++) {
                var i_distance = camera.position.distanceTo(x_Mesh_positionArr[i])
                if (i_distance < nearstDistance) {
                    nearstDistance = i_distance;
                    nearstPosition = x_Mesh_positionArr[i].clone()
                }
            }

            x_Mesh.position.copy(nearstPosition)

            // x_Mesh_positionArr
        },

        //更新ymesh的显示位置
        y_Mesh_UpdatePosition() {

            if (!y_Mesh_positionArr) return

            //看看哪个坐标离相近最近
            var nearstDistance = Infinity
            var nearstPosition
            for (let i = 0; i < y_Mesh_positionArr.length; i++) {
                var i_distance = camera.position.distanceTo(y_Mesh_positionArr[i])
                if (i_distance < nearstDistance) {
                    nearstDistance = i_distance;
                    nearstPosition = y_Mesh_positionArr[i].clone()
                }
            }

            y_Mesh.position.copy(nearstPosition)

            // x_Mesh_positionArr
        },

        //更新ymesh的显示位置
        z_Mesh_UpdatePosition() {

            if (!z_Mesh_positionArr) return

            //看看哪个坐标离相近最近
            var nearstDistance = Infinity
            var nearstPosition
            for (let i = 0; i < z_Mesh_positionArr.length; i++) {
                var i_distance = camera.position.distanceTo(z_Mesh_positionArr[i])
                if (i_distance < nearstDistance) {
                    nearstDistance = i_distance;
                    nearstPosition = z_Mesh_positionArr[i].clone()
                }
            }

            z_Mesh.position.copy(nearstPosition)

            // x_Mesh_positionArr
        },

        //设置正交投影相机的参数，让物体最大化显示
        setOrthCameraData() {
            var Sphere_model = new THREE.Sphere()
            console.log('modex_box33333', modex_box3)
            console.log(Sphere_model)
            console.log(modex_box3)

            modex_box3.getBoundingSphere(Sphere_model)
            // 计算相机的宽高比，通常应与渲染器的canvas保持一致
            let width = window.innerWidth * 0.9
            let height = window.innerHeight * 0.9

            let safedistance = 5

            if (width > height) {

                //宽屏，先确定上下，再按长宽比计算左右
                let bili = width / height
                const viewSize = Sphere_model.radius * 2 + safedistance
                const cameraLeft = -viewSize / 2 * bili;
                const cameraRight = viewSize / 2 * bili;
                const cameraTop = viewSize / 2;
                const cameraBottom = -viewSize / 2;
                camera.left = cameraLeft
                camera.right = cameraRight
                camera.top = cameraTop
                camera.bottom = cameraBottom

            } else {
                //竖屏，先确定左右，再按长宽比计算上下
                let bili = height / width
                const viewSize = Sphere_model.radius * 2 + safedistance
                const cameraLeft = -viewSize / 2;
                const cameraRight = viewSize / 2;
                const cameraTop = viewSize / 2 * bili;
                const cameraBottom = -viewSize / 2 * bili;
                camera.left = cameraLeft
                camera.right = cameraRight
                camera.top = cameraTop
                camera.bottom = cameraBottom

            }



            // 更新相机的投影矩阵
            camera.updateProjectionMatrix();
        },

        //设置字体大小
        setXYZMeshSizeScaleAndSafeDistance() {

            var scaleXYZ, safeDistance

            var max_length = Math.max(modex_box3.max.x - modex_box3.min.x, modex_box3.max.y - modex_box3.min.y, modex_box3.max.z - modex_box3.min.z);
            // if (max_length < 25) {
            //     scaleXYZ = 0.01
            //     safeDistance = 1
            // } else if (max_length < 100) {
            //     scaleXYZ = 0.025
            //     safeDistance = 2
            // } else if (max_length < 200) {
            //     scaleXYZ = 0.04
            //     safeDistance = 3
            // } else if (max_length < 400) {
            //     scaleXYZ = 0.08
            //     safeDistance = 3
            // } else {
            //     scaleXYZ = 0.1
            //     safeDistance = 4
            // }

            scaleXYZ = 0.01 * (max_length / 25).toFixed(2) * 1
            safeDistance = 1 * (max_length / 25).toFixed(2) * 1

            return {
                scaleXYZ,
                safeDistance
            }
        },


        //设置长度单位
        changeLengthUnit() {
            if (this.Length_unit == 'mm') {
                this.Length_unit = 'lego_small'
                this.$notify({
                    // message: '乐高小颗粒',
                    duration: 700,
                    dangerouslyUseHTMLString: true,
                    message: '<strong style="color:rgb(62, 142, 227)">乐高小颗粒单位</strong>'
                });
            } else if (this.Length_unit == 'lego_small') {
                this.Length_unit = 'mm'
                this.$notify({
                    // message: '毫米',
                    duration: 700,
                    // type: 'success'
                    dangerouslyUseHTMLString: true,
                    message: '<strong style="color:rgb(62, 142, 227)">毫米单位</strong>'
                });
            }
            this.setLengthUnit(this.Length_unit)
            if (x_Mesh) x_Mesh.removeFromParent()
            if (y_Mesh) y_Mesh.removeFromParent()
            if (z_Mesh) z_Mesh.removeFromParent()

            this.addXYZMesh()

            this.x_Mesh_UpdatePosition()
            x_Mesh.lookAt(camera.position)

            this.y_Mesh_UpdatePosition()
            y_Mesh.lookAt(camera.position)

            this.z_Mesh_UpdatePosition()
            z_Mesh.lookAt(camera.position)

            const lookAt_ = new THREE.Vector3()
            modex_box3.getCenter(lookAt_)
            console.log('lookAt_', lookAt_)
            //以最佳的大小将模型显示在屏幕中间
            camera.lookAt(lookAt_);
            // camera.lookAt(cameraLookAt);
            console.log('container444', controls, controls.target)
            controls.target.copy(lookAt_);
            controls.update();

            this.setOrthCameraData()
        },

        //设置长度单位
        //lego_small 乐高小颗粒
        setLengthUnit(type) {

            if (type == 'lego_small') {
                //设为乐高小颗粒尺寸


                txt_x_box_length = this.formatNumber(Math.abs(modex_box3.max.x - modex_box3.min.x)) / 20
                txt_y_box_length = this.formatNumber(Math.abs(modex_box3.max.y - modex_box3.min.y)) / 20
                txt_z_box_length = this.formatNumber(Math.abs(modex_box3.max.z - modex_box3.min.z)) / 20

                console.log('尺寸是222', txt_x_box_length, txt_y_box_length, txt_z_box_length)

                this.Modelsize = txt_x_box_length + 'X' + txt_y_box_length + 'X' + txt_z_box_length


            } else if (type == 'mm') {
                //设为毫米尺寸
                txt_x_box_length = this.formatNumber(Math.abs(modex_box3.max.x - modex_box3.min.x)) / 2.5
                txt_y_box_length = this.formatNumber(Math.abs(modex_box3.max.y - modex_box3.min.y)) / 2.5
                txt_z_box_length = this.formatNumber(Math.abs(modex_box3.max.z - modex_box3.min.z)) / 2.5

                console.log('尺寸是222', txt_x_box_length, txt_y_box_length, txt_z_box_length)

                this.Modelsize = txt_x_box_length + 'X' + txt_y_box_length + 'X' + txt_z_box_length


            }

        },

        //设置比例尺
        setRulerData() {

            if (this.rulorShowWidth_input == null || this.rulorShowWidth_input <= 0) {
                this.$notify.error({
                    title: '错误',
                    message: '输入值不正确'
                });
                return

            }

            var container_ = document.querySelector("#rulor_show");

            var width = container_.offsetWidth;

            console.log('比例尺容器的宽度是', width)


            // 323/79

            this.bili_1mm = width / this.rulorShowWidth_input

            localStorage.setItem('bili_1mm', JSON.stringify(this.bili_1mm));



            // var container_infoView = document.querySelector("#infoView")

            // bili_1mm * 8
            //留20的出色线
            this.Number_2 = Math.floor((container_infoView.offsetWidth - 20) / (this.bili_1mm * 8))

            this.Number_mm_rulor = Math.floor((container_infoView.offsetWidth - 20) / (this.bili_1mm) / 10)

            this.dialogFormVisible_setRuler = false

            this.$notify({
                title: '成功',
                message: '校准成功',
                type: 'success'
            });
        }

        // //根据模型设置
        // setXYZMeshSafeDistance() {

        //     var max_length = Math.max(modex_box3.max.x - modex_box3.min.x, modex_box3.max.y - modex_box3.min.y, modex_box3.max.z - modex_box3.min.z);
        //     if (max_length < 25) {
        //         return 0.025
        //     } else if (max_length < 100) {
        //         return 0.05
        //     } else {
        //         return 0.1

        //     }
        // }
    }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.show-part-info-view {
    /* width: 100vw; */
    /* display: flex; */
    align-items: center;
    justify-content: center;

    /* height: 100vh; */
    backdrop-filter: blur(5px);

}

.hide-part-info-view {
    /* width: 100vw; */
    /* display: flex;
    align-items: center; */
    justify-content: center;

    /* height: 100vh; */
    backdrop-filter: blur(5px);

    /* margin-top: -500vh; */
    position: absolute;
    top: -500vh;
    left: 0;
}

#textButton {
    background-color: transparent;
    border: none;
    color: #46a0fc;
    padding: 0;
    margin: 0;
    font-size: 16px;
    /* 根据需要调整字体大小 */
    cursor: pointer;
    /* 将鼠标指针改为指针形状 */
}
</style>