<template>
    <div v-if="ImageUrl"
        style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;background-color: rgb(0, 0, 0);position: absolute;left: 0;top: 0;">

        <div
            style="width: 100%;height:100% ;background-color: rgb(0, 0, 0);display: flex;align-items: center;justify-content: center;">

            <el-image @load="handelimageload" 
                @click="OperationImage(ImageUrl)" style="width: 100%; height: 100%;background-color: rgb(0, 0, 0);"
                :src="ImageUrl" @dragstart.prevent @dragover.prevent @drop.prevent fit="contain"></el-image>

            <!-- <img ref="myimage" :src="ImageUrl" alt="Description"> -->

            <!-- <viewer >
               
            </viewer> -->





            <div
                style="position:absolute;top: 0px;width: 100%;display: flex;align-items: center;flex-direction: row-reverse;">
                <el-button @click="closeImagePlayer"
                    style="padding: 0px;font-size: xx-large;margin: 10px;background-color: #fff;" type="text"
                    icon="el-icon-close" circle>
                </el-button>
            </div>

        </div>


    </div>
</template>
<script>



var loading

// import 'viewerjs/dist/viewer.css'
// import VueViewer from 'v-viewer'
// import Vue from 'vue'
// Vue.use(VueViewer)



export default {
    data() {
        return {
            // videoUrl: null,
            // playVideoButtonShow: true,
            // progress: 0,
            // nowTime: 0,
            // totalTime: 0,
            ImageUrl: null

        }
    },





    mounted() {

        this.$parent.$on('messageToImagePlayer', this.handleParentMessage);




        window.addEventListener('resize', this.windowResize)

        this.initViewer()

        // this.gettempKey()


        // this.getvideoPlayerView()
        // this.getMediaUrl()
    },


    beforeDestroy() {

        window.removeEventListener("resize", this.windowResize);
        // window.removeEventListener("mousemove", this.windowResize);

    },

    props: {
        // images: Array
    },

    // beforeDestroy() {

    // },





    methods: {


        windowResize() {

            // this.loadedmetadata()
            // this.setVideoWidthAndHeight()

        },




        handleParentMessage(e) {

            console.log('handleParentMessage_e222222', e)
            // this.getvideoPlayerView(e.Key)

            this.getMediaUrl(e)

        },

        //关闭图片播放器
        closeImagePlayer() {

            this.ImageUrl = null

            this.$router.back();


        },

        handelimageload() {
            console.log('图片加载完成')

            loading.close()

            loading = null

        },

        initViewer() {
            // this.viewerInstance = new Viewer(this.$refs.myImage, {
            //     // 配置项
            //     hidden: () => {
            //         console.log('The viewer has been closed.')
            //         // 在这里可以添加任何你想要执行的逻辑
            //     },
            // })
        },


        // closeVideoPlayer() {

        //     this.videoUrl = null
        //     this.playVideoButtonShow = true
        //     this.progress = 0
        //     this.nowTime = 0
        //     this.totalTime = 0

        //     // this.$refs.videoPlayer.pause()

        //     //显示播放按钮
        //     // this.playVideoButtonShow = true
        // },

        //判断key是不是用户自己的？
        // this.$root.$USERINFO.userFolder
        isKeyOfUser(key, userFolder) {
            // UsersFolder / 886f5319281740f88009117c2ddf9b2b

            // if (userFolder == null) return false

            const KeyArr = key.split('/')

            if (KeyArr[1] == userFolder) {

                return true

            } else {

                return false

            }

        },

        async getMediaUrl(e) {

            console.log('getMediaUrle', e)

            if (this.$root.$USERINFO && this.$root.$USERINFO.userFolder && this.isKeyOfUser(e.Key, this.$root.$USERINFO.userFolder)) {
                //showMyWPPS
                this.showMyImage(e.Key)

            } else {
                //分享的办公文件
                this.showSharerImage(e)

            }


        },

        async showMyImage(key) {
            const that = this

            var res = await this.$root.$MyCloudFiles.getMyCloudMideaUrl(key)

            if (res == 'err') {

                this.$message.error('资源连接错误');

                return
            }

            loading = this.$loading({
                lock: true,
                text: '加载数据中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            setTimeout(() => {

                if (loading != null) {

                    loading.close()
                    loading = null

                    that.$message({
                        message: '超时！请检查网络环境或者资源过大',
                        type: 'warning'
                    });
                }


            }, 5000);

            console.log('多媒体的预览地址是', res)

            this.ImageUrl = res


            // setTimeout(() => {

            //     console.log(that.$refs.myimage)
            //     const viewer = that.$refs.myImageView.$viewer
            //     if (viewer) viewer.show()



            // }, 50);



        },

        //旋转缩放，使用图片查看器查看图片
        OperationImage(url) {

            this.$viewerApi({
                options: {
                    //不显示缩略图列表
                    navbar: false
                },
                images: [url],
            })

        },

        //获取分享的文件
        async showSharerImage(e) {
            const that = this

            // TemporaryKey
            // BucketInfo

            //不是该用户的文件夹，则需要传入临时密钥
            const TemporaryKey = e.temporary_key

            const BucketInfo = {
                Bucket: TemporaryKey.bucket,
                Region: TemporaryKey.region
            }

            // var res = await this.$root.$ShareCloudFiles.getShareCloudMideaUrl(e.Key, TemporaryKey, BucketInfo)
            var res = await this.$root.$ShareCloudFiles_inSchool.getShareCloudMideaUrl(e.Key, TemporaryKey, BucketInfo)

            console.log('临时图片链接结果', res)
            if (res == 'err') {

                this.$message.error('资源连接错误');

                return
            }

            loading = this.$loading({
                lock: true,
                text: '加载数据中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            setTimeout(() => {

                if (loading != null) {

                    loading.close()
                    loading = null

                    that.$message({
                        message: '超时！请检查网络环境或者资源过大',
                        type: 'warning'
                    });
                }


            }, 5000);

            console.log('多媒体的预览地址是', res)

            this.ImageUrl = res

        },




    }
}
</script>
<style></style>