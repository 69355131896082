<template>
    <div style="width: 100%;height: 100%;user-select: none;display: flex;align-items: center;justify-content: center;">

        <div
            style="width: 90%;height: 90%;background-color: #eaeaea;display: flex;flex-direction: column;align-items: center;justify-content: center;border-radius: 20px;">

            <div style="width: 95%;height: 95%;">

                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="动物" name="animal">

                        <!-- this.imageArr -->
                        <el-button type="text" @click="clickElement(item.name, item.url, item.label)"
                            v-for="( item, index ) in imageArr.animal " :key="index">
                            <div style="width: 40px;height: 40px;color: #ff2e63;margin: 4px;">
                                <img :src="item.url" alt="" style="width: 100%;height: 100%;">
                                <div>{{ item.name }}</div>
                            </div>
                        </el-button>

                        <!-- 动物 -->
                    </el-tab-pane>
                    <el-tab-pane label="水果" name="fruit">

                        <el-button type="text" @click="clickElement(item.name, item.url, item.label)"
                            v-for="( item, index ) in imageArr.fruit " :key="index">
                            <div style="width: 40px;height: 40px;color: #ff2e63;margin: 4px;">
                                <img :src="item.url" alt="" style="width: 100%;height: 100%;">
                                <div>{{ item.name }}</div>
                            </div>
                        </el-button>
                        <!-- 水果 -->
                    </el-tab-pane>
                    <el-tab-pane label="蔬菜" name="vegetable">
                        <el-button type="text" @click="clickElement(item.name, item.url, item.label)"
                            v-for="( item, index ) in imageArr.vegetable " :key="index">
                            <div style="width: 40px;height: 40px;color: #ff2e63;margin: 4px;">
                                <img :src="item.url" alt="" style="width: 100%;height: 100%;">
                                <div>{{ item.name }}</div>
                            </div>
                        </el-button>
                    </el-tab-pane>
                    <el-tab-pane label="军事" name="military">
                        <el-button type="text" @click="clickElement(item.name, item.url, item.label)"
                            v-for="( item, index ) in imageArr.arms " :key="index">
                            <div style="width: 40px;height: 40px;color: #ff2e63;margin: 4px;">
                                <img :src="item.url" alt="" style="width: 100%;height: 100%;">
                                <div>{{ item.name }}</div>
                            </div>
                        </el-button>
                    </el-tab-pane>
                </el-tabs>
            </div>


            <div style="display: flex;align-items: center;margin-top: 10px;">
                <!-- <div>我的输入:</div> -->
                <div>
                    <el-button type="text" v-for="( item, index ) in myInput" :key="index" style="position: relative;">

                        <div @click="deleteElement(index)" v-on:mouseenter="handleMouseEnterPassWords(index)"
                            v-on:mouseleave="handleMouseLeavePassWords">

                            <img :src="item.url" alt="" style="width: 50px;height: 50px;">

                            <!-- <div>{{ item.name }}</div>
                            <div>{{ item.label }}</div> -->

                            <div v-if="hoverIndex == index"
                                style="width: 50px;height: 50px;font-size: 50px;position: absolute;z-index: 10000;left: 0;top: 0;display: flex;align-items: center;justify-content: center;color: #ff2e63;">
                                <div>X</div>
                            </div>

                        </div>


                    </el-button>

                </div>
            </div>

            <div>

                <el-button type="text" style="padding: 10px;" @click="sendDataToParent">
                    <div
                        style="width: 50px;height: 30px;background-color: #44bd32;border-radius: 10px;padding: 10px;display: flex;align-items: center;justify-content: center;">
                        <div style="color: #252a34;color: #f5f6fa;">输入完成</div>
                    </div>
                </el-button>

            </div>


        </div>

    </div>
</template>

<script>
export default {
    name: 'PasswordInputTool',
    data() {
        return {
            message: '这是一个简单的Vue 2组件',
            activeName: 'animal',
            imageArr: [],
            myInput: [],
            hoverIndex: null,
            childData: {}

        }
    },
    mounted() {

        this.setImageArr()

    },
    methods: {
        changeMessage() {
            this.message = '消息已更新！';
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
        setImageArr() {
            this.imageArr = {

                //动物
                animal: [
                    {
                        name: "蜜蜂",
                        label: "bee",
                        url: "/passwordsImage/animal/bee.svg"
                    },

                    {

                        label: "bird",
                        name: "鸟",
                        url: "/passwordsImage/animal/bird.svg"
                    },

                    {
                        label: "butterfly",
                        name: "蝴蝶",
                        url: "/passwordsImage/animal/butterfly.svg"
                    },

                    {
                        label: "cattle",
                        name: "牛",
                        url: "/passwordsImage/animal/cattle.svg"
                    },

                    {
                        label: "chicken",
                        name: "鸡",
                        url: "/passwordsImage/animal/chicken.svg"
                    },

                    {
                        label: "crab",
                        name: "螃蟹",
                        url: "/passwordsImage/animal/crab.svg"
                    },

                    {
                        label: "deer",
                        name: "鹿",
                        url: "/passwordsImage/animal/deer.svg"
                    },

                    {
                        label: "dog",
                        name: "狗",
                        url: "/passwordsImage/animal/dog.svg"
                    },


                    {
                        label: "dolphin",
                        name: "海豚",
                        url: "/passwordsImage/animal/dolphin.svg"
                    },

                    {
                        label: "eagle",
                        name: "老鹰",
                        url: "/passwordsImage/animal/eagle.svg"
                    },

                    {
                        label: "elephant",
                        name: "大象",
                        url: "/passwordsImage/animal/elephant.svg"
                    },

                    {
                        label: "fish",
                        name: "鱼",
                        url: "/passwordsImage/animal/fish.svg"
                    },






                    {
                        label: "horse",
                        name: "马",
                        url: "/passwordsImage/animal/horse.svg"
                    },

                    {
                        label: "monkey",
                        name: "猴子",
                        url: "/passwordsImage/animal/monkey.svg"
                    },

                    {
                        label: "octopus",
                        name: "章鱼",
                        url: "/passwordsImage/animal/octopus.svg"
                    },

                    {
                        label: "penguin",
                        name: "企鹅",
                        url: "/passwordsImage/animal/penguin.svg"
                    },



                    {
                        label: "pig",
                        name: "猪",
                        url: "/passwordsImage/animal/pig.svg"
                    },

                    {
                        label: "seahorse",
                        name: "海马",
                        url: "/passwordsImage/animal/seahorse.svg"
                    },

                    {
                        label: "sheep",
                        name: "羊",
                        url: "/passwordsImage/animal/sheep.svg"
                    },

                    {
                        label: "snail",
                        name: "蜗牛",
                        url: "/passwordsImage/animal/snail.svg"
                    },


                    {
                        label: "snake",
                        name: "蛇",
                        url: "/passwordsImage/animal/snake.svg"
                    },

                    {
                        label: "tiger",
                        name: "老虎",
                        url: "/passwordsImage/animal/tiger.svg"
                    },

                    {
                        label: "turtle",
                        name: "乌龟",
                        url: "/passwordsImage/animal/turtle.svg"
                    }

                ],

                //水果
                fruit: [
                    {
                        name: "菠萝",
                        label: "pineapple",
                        url: "/passwordsImage/fruit/pineapple.svg"
                    },

                    {

                        label: "strawberry",
                        name: "草莓",
                        url: "/passwordsImage/fruit/strawberry.svg"
                    },

                    {
                        label: "orange",
                        name: "橙子",
                        url: "/passwordsImage/fruit/orange.svg"
                    },

                    {
                        label: "blueberry",
                        name: "蓝莓",
                        url: "/passwordsImage/fruit/blueberry.svg"
                    },

                    {
                        label: "pear",
                        name: "梨",
                        url: "/passwordsImage/fruit/pear.svg"
                    },

                    {
                        label: "litchi",
                        name: "荔枝",
                        url: "/passwordsImage/fruit/litchi.svg"
                    },

                    {
                        label: "durian",
                        name: "榴莲",
                        url: "/passwordsImage/fruit/durian.svg"
                    },

                    {
                        label: "mango",
                        name: "芒果",
                        url: "/passwordsImage/fruit/mango.svg"
                    },


                    {
                        label: "lemon",
                        name: "柠檬",
                        url: "/passwordsImage/fruit/lemon.svg"
                    },

                    {
                        label: "apple",
                        name: "苹果",
                        url: "/passwordsImage/fruit/apple.svg"
                    },

                    {
                        label: "grape",
                        name: "葡萄",
                        url: "/passwordsImage/fruit/grape.svg"
                    },

                    {
                        label: "peach",
                        name: "桃子",
                        url: "/passwordsImage/fruit/peach.svg"
                    },






                    {
                        label: "watermelon",
                        name: "西瓜",
                        url: "/passwordsImage/fruit/watermelon.svg"
                    },

                    {
                        label: "banana",
                        name: "香蕉",
                        url: "/passwordsImage/fruit/banana.svg"
                    },

                    {
                        label: "cherry",
                        name: "樱桃",
                        url: "/passwordsImage/fruit/cherry.svg"
                    },

                    {
                        label: "carambola",
                        name: "杨桃",
                        url: "/passwordsImage/fruit/carambola.svg"
                    },
                    {
                        label: "coco",
                        name: "椰子",
                        url: "/passwordsImage/fruit/coco.svg"
                    },

                    {
                        label: "pitaya",
                        name: "火龙果",
                        url: "/passwordsImage/fruit/pitaya.svg"
                    },

                    {
                        label: "avocado",
                        name: "牛油果",
                        url: "/passwordsImage/fruit/avocado.svg"
                    },

                    {
                        label: "mangosteen",
                        name: "山竹",
                        url: "/passwordsImage/fruit/mangosteen.svg"
                    }


                ],



                //军事武器
                arms: [
                    {
                        name: "大炮",
                        label: "cannon",
                        url: "/passwordsImage/arms/cannon.svg"
                    },

                    {

                        label: "missile",
                        name: "导弹",
                        url: "/passwordsImage/arms/missile.svg"
                    },

                    {
                        label: "mine",
                        name: "地雷",
                        url: "/passwordsImage/arms/mine.svg"
                    },

                    {
                        label: "nuclearBomb",
                        name: "核弹",
                        url: "/passwordsImage/arms/nuclearBomb.svg"
                    },

                    {
                        label: "warship",
                        name: "军舰",
                        url: "/passwordsImage/arms/warship.svg"
                    },

                    {
                        label: "handGrenade",
                        name: "手雷",
                        url: "/passwordsImage/arms/handGrenade.svg"
                    },

                    {
                        label: "pistol",
                        name: "手枪",
                        url: "/passwordsImage/arms/pistol.svg"
                    },


                    {
                        label: "tank",
                        name: "坦克",
                        url: "/passwordsImage/arms/tank.svg"
                    },

                    {
                        label: "satellite",
                        name: "卫星",
                        url: "/passwordsImage/arms/satellite.svg"
                    },

                    {
                        label: "drone",
                        name: "无人机",
                        url: "/passwordsImage/arms/drone.svg"
                    },

                    {
                        label: "gunship",
                        name: "直升机",
                        url: "/passwordsImage/arms/gunship.svg"
                    },


                    {
                        label: "fighter",
                        name: "战斗机",
                        url: "/passwordsImage/arms/fighter.svg"
                    },

                    {
                        label: "soldier",
                        name: "战士",
                        url: "/passwordsImage/arms/soldier.svg"
                    }


                ],

                //蔬菜
                vegetable: [
                    {
                        name: "白菜",
                        label: "cabbage",
                        url: "/passwordsImage/vegetable/cabbage.svg"
                    },

                    {

                        label: "scallion",
                        name: "大葱",
                        url: "/passwordsImage/vegetable/scallion.svg"
                    },

                    {
                        label: "garlic",
                        name: "大蒜",
                        url: "/passwordsImage/vegetable/garlic.svg"
                    },

                    {
                        label: "sweetPotato",
                        name: "红薯",
                        url: "/passwordsImage/vegetable/sweetPotato.svg"
                    },

                    {
                        label: "carrot",
                        name: "胡萝卜",
                        url: "/passwordsImage/vegetable/carrot.svg"
                    },

                    {
                        label: "peanut",
                        name: "花生",
                        url: "/passwordsImage/vegetable/peanut.svg"
                    },

                    {
                        label: "eggplant",
                        name: "茄子",
                        url: "/passwordsImage/vegetable/eggplant.svg"
                    },


                    {
                        label: "pumpkin",
                        name: "南瓜",
                        url: "/passwordsImage/vegetable/pumpkin.svg"
                    },

                    {
                        label: "greenPepper",
                        name: "青椒",
                        url: "/passwordsImage/vegetable/greenPepper.svg"
                    },

                    {
                        label: "ginger",
                        name: "生姜",
                        url: "/passwordsImage/vegetable/ginger.svg"
                    },

                    {
                        label: "potato",
                        name: "土豆",
                        url: "/passwordsImage/vegetable/potato.svg"
                    },


                    {
                        label: "pea",
                        name: "豌豆",
                        url: "/passwordsImage/vegetable/pea.svg"
                    },

                    {
                        label: "tomato",
                        name: "西红柿",
                        url: "/passwordsImage/vegetable/tomato.svg"
                    },


                    {
                        label: "broccoli",
                        name: "西兰花",
                        url: "/passwordsImage/vegetable/broccoli.svg"
                    },


                    {
                        label: "mushroom",
                        name: "蘑菇",
                        url: "/passwordsImage/vegetable/mushroom.svg"
                    },

                    {
                        label: "onion",
                        name: "洋葱",
                        url: "/passwordsImage/vegetable/onion.svg"
                    },


                    {
                        label: "corn",
                        name: "玉米",
                        url: "/passwordsImage/vegetable/corn.svg"
                    }

                ],





            }

        },
        //将数据，比如将密码发送到父级
        sendDataToParent() {
            // 触发自定义事件 'child-to-parent'，并传递数据
            // 将密码取出来
            var passWords = ''
            for (let i = 0; i < this.myInput.length; i++) {

                passWords += this.myInput[i].label

                if (i + 1 < this.myInput.length) {

                    passWords += "_"

                }

                // passWords.push(this.myInput[i].label)

            }

            this.childData.password = passWords
            this.$emit('child-to-parent', this.childData);
        },

        clickElement(name, url, label) {
            this.myInput.push(
                {
                    name,
                    url,
                    label
                }
            );

            this.hoverIndex = null

        },
        handleMouseEnterPassWords(index) {

            console.log("移入index", index)
            this.hoverIndex = index
        },

        handleMouseLeavePassWords() {
            this.hoverIndex = null
        },

        //密码修改，删除指定下标的元素
        deleteElement(index) {

            this.myInput.splice(index, 1); // index是要删除的元素的下标，1是要删除的元素数量

        }

    }
}
</script>

<style scoped></style>