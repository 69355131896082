import * as THREE from 'three';
import { RoomEnvironment } from './RoomEnvironment.js';

//半透明材质
// const material_translucent = new THREE.MeshBasicMaterial({
//     color: 0x888888,
//     // depthTest: false,
//     // depthWrite: false,
//     opacity: 0.3,
//     transparent: true,
// });

var stepGroup

import WebGL from '../js/WebGL.js';




// var part_box3 = new THREE.Box3();
//零件的中心点

let _partlist = []
var _camera, _scene, _renderer

//主程序上用来渲染零件的容器
var _partContainer

// var indexedDBPartsNames = []

// let partModel

//画布尺寸
// let canvasWidth = window.innerWidth * 0.18
// let canvasHeight = 250

let canvasWidth = 500
let canvasHeight = 500

//支持webgl才生成渲染器


_camera = new THREE.PerspectiveCamera(8, 1, 1, 50000);

// _camera = new THREE.PerspectiveCamera(20, aspect, 1, 10000);
//抗锯齿和透明度

if (WebGL.isWebGL2Available()) {
    _renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });




    // renderer = new THREE.WebGLRenderer( { alpha: true } )
    _renderer.setPixelRatio(window.devicePixelRatio);
    // renderer.setSize(window.innerWidth - 50, window.innerHeight - 110);

    //渲染图尺寸
    _renderer.setSize(canvasWidth, canvasHeight);

    // renderer.setSize(window.innerWidth, window.innerHeight);
    // _renderer.outputEncoding = THREE.sRGBEncoding;
    _renderer.toneMapping = THREE.ACESFilmicToneMapping;
    // _pmremGenerator = new THREE.PMREMGenerator(_renderer);
    _scene = new THREE.Scene();
    const pmremGenerator = new THREE.PMREMGenerator(_renderer);
    // _scene.background = new THREE.Color(0x1C2A39);
    _scene.environment = pmremGenerator.fromScene(new RoomEnvironment()).texture;

}



// _scene



console.log('零件渲染器初始化完成')

// import WebGL from 'src/js/WebGL.js';

class MpdPartListCreater {

    constructor(partContainer) {

        // _model = model;

        _partContainer = partContainer

        _partContainer.appendChild(_renderer.domElement);

    }

    // this.checkWebGLSupport()

    // checkWebGLSupport() {
    //     console.log('WebGL3333', WebGL)
    //     if (WebGL.isWebGL2Available()) {
    //         return true

    //     } else {
    //         const warning = WebGL.getWebGL2ErrorMessage();
    //         console.error('WebGL 2 不支222持!', warning);
    //         return false

    //     }
    // }


    //传入一个数组（里面是零件），然后将零件对应的图片返回出去
    async getImagesArr(GroupArr_) {

        const that = this


        //传入的是步骤，如果传入的是组，则检测组内有没有和步骤零件步骤相同的零件，如果有，则将子代的零件也拿出来生成图片
        //如果组是替身组，则不找后代
        const GroupArr = this.findAllSameGlobalPartsArr(GroupArr_)

        console.log('GroupArr888', GroupArr)





        // for (let i = 0; i < GroupArr_.length; i++) {
        //     GroupArr_.traverse(c => ({

        //         //     if(c.userData.TYPE && c.userData.TYPE == 'PART'){

        //         // }

        //     }))
        // }



        // GroupArr = []

        console.log('GroupArr222', GroupArr)

        var ImagesArr = []

        //_partlist
        for (let i = 0; i < GroupArr.length; i++) {


            //看看有没有相同的零件型号已经存在的？有就只用将相应的ImagesArr_i 数量加1
            var sameImage = this.samePartInImage_arr(ImagesArr, GroupArr[i])

            console.log('sameImage939393', sameImage)


            //如果已经有相同名称和相同颜色的零件，则不用重新生成图片，只用将数量增加1
            if (sameImage) {
                sameImage.num++

                //有就不用在下面找了，而是找下一个零件
                continue

            }

            //去名字的空格
            const partOrGroupName = GroupArr[i].userData.fileName.indexOf(' ') != -1 ? GroupArr[i].userData.fileName.replace(/\s/g, '-') : GroupArr[i].userData.fileName
            const partOrGroupColor = GroupArr[i].userData.colorCode
            const partOrGroupTYPE = GroupArr[i].userData.TYPE
            const PartOrGroupid = GroupArr[i].id
            const isStandInGroup = GroupArr[i].userData.TYPE == "GROUP" && GroupArr[i].userData.isStandInGroup ? true : false




            // var imageINFO = await this.getImageFromIndexedDB(partOrGroupName + '-' + partOrGroupColor, objectStore)

            // PromiseTweenArr.push(



            // )

            ImagesArr.push({
                num: 1,
                id: PartOrGroupid,
                // id: imageINFO.imageData.id,
                // url: imageINFO.imageData.url,
                partname: partOrGroupName,
                colorCode: partOrGroupColor,
                type: partOrGroupTYPE,
                isStandInGroup: isStandInGroup
            })


        }

        const objectStore = await this.createDB()
        var PromiseTweenArr = []

        //集中获取照片数据
        //并发保存
        for (let o = 0; o < ImagesArr.length; o++) {

            // partname: partOrGroupName,
            // colorCode: partOrGroupColor,

            PromiseTweenArr.push(

                new Promise(function (resolve) {


                    that.getImageData(ImagesArr[o], objectStore, (onsuccess) => {

                        console.log('接收到的图片数据rrr', onsuccess)

                        resolve(onsuccess)

                    })

                    // tweenArray[j].tween.onComplete(resolve);

                })

            )

        }

        var imageArray = await Promise.all(PromiseTweenArr);

        console.log('本步骤生成的零件图是是PromiseTweenArr', PromiseTweenArr);


        // 使用 .then 链获取每个 Promise 的值，构建实际的图片数据数组
        // const imageArray = PromiseTweenArr.map(function(promises) {

        //     return promises[0].value;

        // })
        // const imageArray = PromiseTweenArr.map( PromiseTweenArr[0].value);
        ImagesArr = null
        console.log('本步骤生成的零件图是是', imageArray);
        GroupArr_ = null
        return imageArray;
        // var imageINFO = await this.getImageFromIndexedDB(partOrGroupName + '-' + partOrGroupColor, objectStore)

        // console.log('本步骤生成的零件图是是', PromiseTweenArr)

        // // return ImagesArr
        // return PromiseTweenArr

    }

    //获取图片数据
    //并发获取
    async getImageData(IMageinfo, objectStore, onsuccess) {


        // partOrGroupName + '-' + partOrGroupColor

        var imageINFO = await this.getImageFromIndexedDB(IMageinfo.partname + '-' + IMageinfo.colorCode, objectStore)
        console.log('db中获取到的图片信息', imageINFO)
        var ImagesInfo = {
            num: IMageinfo.num,
            id: IMageinfo.id,
            // id: imageINFO.imageData.id,
            url: imageINFO.imageData.url,
            partname: IMageinfo.partname,
            colorCode: IMageinfo.colorCode,
            type: IMageinfo.type,
            isStandInGroup: IMageinfo.isStandInGroup
        }

        console.log('ImagesInfo4455', ImagesInfo)

        console.log('获取到图片333', imageINFO)


        onsuccess(ImagesInfo)

    }


    // ImagesArr.push({
    //     num: 1,
    //     // id: imageINFO.imageData.id,
    //     // url: imageINFO.imageData.url,
    //     partname: partOrGroupName,
    //     colorCode: partOrGroupColor,
    //     type: partOrGroupTYPE

    // })


    //传入一个组，则将组内的children按照组内步骤分组生成图片
    //并发生成
    async CreatePartListImagesArr(GroupArr, force = false) {

        var PromiseTweenArr = []

        console.log('111GroupArr111', GroupArr)



        // getImagesArr
        // _scene.environment = _pmremGenerator.fromScene(new RoomEnvironment()).texture;

        //移除上一次的场景零件
        if (stepGroup) {

            _scene.remove(stepGroup);


            console.log('释放上次物体的资源333')
            this.disposeGroup(stepGroup)

        }

        _partlist = []
        // this.CreateStepImagesArrFromGroup(GroupArr)

        const objectStore = await this.createDB()


        for (let i = 0; i < GroupArr.length; i++) {


            console.log('等待生成零件图的零件是:', GroupArr[i])

            // if (GroupArr[i].userData.TYPE != 'PART') { continue }

            //确认indexeddb中是否有这个零件，如果有则不用生成
            //如果强制则强制生成

            //不强制则执行本地检测，看看本地有没有
            console.log('333force33', force)
            // if(!force){

            //     if(PartImageInDb(GroupArr[i])){

            //         continue

            //     }

            // }

            stepGroup = GroupArr[i].clone()

            stepGroup.position.set(0, 0, 0)

            stepGroup.rotation.set(Math.PI, 0, 0)

            _scene.add(stepGroup)

            //将所有子孙中的替身组中的第一个留下来，其他移除
            this.removeStandInObjInChildren(stepGroup)

            // if (stepGroup.userData.isStandInGroup) {
            //     console.log('当前的组是替身组', stepGroup)
            // }


            // //替身组内设置替身可见性
            // if (stepGroup.userData.TYPE == 'GROUP' && stepGroup.userData.isStandInGroup) {
            //     this.setStandInVisibleInStep(stepGroup)
            // }


            const resImagePart = this.greateTempImgURL(stepGroup)

            console.log('resImagePart ', resImagePart)



            //去名字的空格
            var partOrGroupName = stepGroup.userData.fileName.indexOf(' ') != -1 ? stepGroup.userData.fileName.replace(/\s/g, '-') : stepGroup.userData.fileName
            var partOrGroupColor = stepGroup.userData.colorCode

            if (stepGroup.userData.TYPE == 'GROUP') {

                console.log('生成组的图片成功', stepGroup, resImagePart)

                console.log(partOrGroupName + '-' + partOrGroupColor)

                // console.log({
                //     id: GroupArr[i].id,
                //     url: resImagePart,
                //     partname: stepGroup.userData.fileName,
                //     colorCode: stepGroup.userData.colorCode,
                //     type: stepGroup.userData.TYPE
                // })

                // await this.saveIMageToIndexedDB('888888888888888888888888', {
                //     name: '测试'
                //     // id: GroupArr[i].id,
                //     // url: resImagePart,
                //     // partname: stepGroup.userData.fileName,
                //     // colorCode: stepGroup.userData.colorCode,
                //     // type: stepGroup.userData.TYPE
                // })

            }

            PromiseTweenArr.push(

                this.saveIMageToIndexedDB(partOrGroupName + '-' + partOrGroupColor, {
                    id: GroupArr[i].id,
                    url: resImagePart,
                    partname: stepGroup.userData.fileName,
                    colorCode: stepGroup.userData.colorCode,
                    type: stepGroup.userData.TYPE,
                    //是否是替身组
                    isStandInGroup: stepGroup.userData.TYPE == "GROUP" && stepGroup.userData.isStandInGroup ? true : false
                }, objectStore)

            )

            // await this.saveIMageToIndexedDB(partOrGroupName + '-' + partOrGroupColor, {
            //     id: GroupArr[i].id,
            //     url: resImagePart,
            //     partname: stepGroup.userData.fileName,
            //     colorCode: stepGroup.userData.colorCode,
            //     type: stepGroup.userData.TYPE
            // },objectStore)




            // await this.saveIMageToIndexedDB(stepGroup.userData.fileName, {
            //     id: 22,
            //     url: 33,
            //     partname: stepGroup.userData.fileName,
            //     colorCode: 44,
            // })

            // await this.getImageFromIndexedDB(stepGroup.userData.fileName)


            // await this.getImageFromIndexedDB(stepGroup.userData.fileName + '-' + stepGroup.userData.colorCode)



            // _partlist.push({
            //     id: GroupArr[i].id,
            //     url: resImagePart,
            //     partname: stepGroup.userData.fileName,
            //     colorCode: stepGroup.userData.colorCode,
            // })


            _scene.remove(stepGroup);
            console.log('释放上次物体的资源3333223')
            this.disposeGroup(stepGroup)
            stepGroup = null
            // this.clearScene(stepGroup)

        }



        await Promise.all(PromiseTweenArr);






        //移除上一次的场景零件
        if (stepGroup) {

            _scene.remove(stepGroup);
            console.log('释放上次物体的资源33332err')
            this.disposeGroup(stepGroup)

        }

        console.log('所有的图片生成完成', _partlist)


        // return _partlist


    }


    //释放资源
    disposeGroup(group) {
        // 遍历当前组中的所有子对象
        group.children.forEach(child => {
            if (child instanceof THREE.Group) {
                // 如果子对象也是一个组，递归清理
                this.disposeGroup(child);
            } else if (child instanceof THREE.Mesh ||
                child instanceof THREE.Line ||
                child instanceof THREE.Points ||
                child instanceof THREE.Sprite) {
                // 如果是3D对象，清理它的材质和几何体
                disposeMesh(child);
            } else if (child instanceof THREE.Light) {
                // 如果是光照，不需要额外的处置，除非有特殊的光照纹理需要处理
                // 一般情况下，光照对象自身不需要dispose操作
            }
        });

        // 如果当前组有父级，并且需要从父级中移除
        if (group.parent !== null) {
            group.parent.remove(group);
        }

        // 清理3D对象的方法
        function disposeMesh(object) {
            if (object.geometry) object.geometry.dispose();
            if (object.material) {
                if (Array.isArray(object.material)) {
                    object.material.forEach(mat => mat.dispose());
                } else {
                    object.material.dispose();
                }
            }
        }
    }
    // disposeGroup(group) {
    //     // 遍历当前组中的所有子对象
    //     group.children.forEach(child => {
    //         if (child instanceof THREE.Group) {
    //             // 如果子对象也是一个组，递归清理
    //             this.disposeGroup(child);
    //         } else if (child instanceof THREE.Mesh ||
    //             child instanceof THREE.Line ||
    //             child instanceof THREE.Points ||
    //             child instanceof THREE.Sprite) {
    //             // 如果是3D对象，清理它的材质和几何体
    //             disposeMesh(child);
    //         } else if (child instanceof THREE.Light) {
    //             // 如果是光照，不需要额外的处置，除非有特殊的光照纹理需要处理
    //             // 一般情况下，光照对象自身不需要dispose操作
    //         }
    //     });

    //     // 如果当前组有父级，并且需要从父级中移除
    //     if (group.parent !== null) {
    //         group.parent.remove(group);
    //     }

    //     // 清理3D对象的方法
    //     function disposeMesh(object) {
    //         console.log('释放mesh图片生成器', object.geometry)
    //         if (object.geometry) {

    //             const newGeometry = new THREE.BufferGeometry();

    //             object.geometry.copy(newGeometry);

    //             // 清理旧的 BufferGeometry
    //             object.geometry.dispose();

    //             // 替换新的 BufferGeometry
    //             object.geometry = newGeometry;
    //         }
    //         // if (object.geometry) object.geometry.dispose();
    //         //单一零件的材质应该是数字或者数组
    //         // if (object.material) {
    //         // 	if (Array.isArray(object.material)) {
    //         // 		object.material.forEach(mat => mat.dispose());
    //         // 	} else {
    //         // 		console.log('object.materialrrrr',object.material)
    //         // 		object.material.dispose();
    //         // 	}
    //         // }
    //     }
    // }



    //检测零件是否则indexdb中存在
    // PartImageInDb(PartOrGroup){

    //     // if(){

    //     // }
    // }


    // clearScene(scene) {
    //     // 遍历场景中的所有对象
    //     scene.traverse(function (child) {
    //         // 处理网格对象和其他可卸载的对象
    //         if (child instanceof THREE.Mesh || child instanceof THREE.Light || child instanceof THREE.Camera) {
    //             // 如果对象有材质，遍历材质并处置
    //             if (child.material instanceof THREE.Material) {
    //                 if (Array.isArray(child.material)) { // 处理数组类型的材质（比如多材质对象）
    //                     child.material.forEach(function (material) {
    //                         material.dispose();
    //                     });
    //                 } else {
    //                     child.material.dispose();
    //                 }
    //             }
    //         }

    //         // 如果对象是几何体，处置几何体
    //         if (child.geometry) {
    //             child.geometry.dispose();
    //         }

    //         // 从场景中移除对象
    //         scene.remove(child);
    //     });

    //     // 清除场景本身可能引用的其他资源，根据实际使用情况可能需要定制化处理
    // }


    //将所有子孙中的替身组中的第一个留下来，其他移除
    removeStandInObjInChildren(stepGroup) {

        var StandInArr = []

        var findChildren = function (GROUp) {

            for (let i = 0; i < GROUp.children.length; i++) {


                if (GROUp.children[i].userData.TYPE == 'GROUP') {

                    if (GROUp.children[i].userData.isStandInGroup) {
                        //如果是替身组
                        //只留下替身组内的第一个对象，其他后面的都删除
                        // if(i > 0){

                        //     StandInArr.push(GROUp.children[i])

                        // }

                        var StandInGroup = GROUp.children[i]

                        for (let j = 0; j < StandInGroup.children.length; j++) {

                            if (j > 0) {
                                StandInArr.push(StandInGroup.children[j])
                            }

                        }



                    } else {
                        //如果不是替身组，则可以继续往下找
                        findChildren(GROUp.children[i])
                    }

                }



            }



        }


        //如果传入的本来就是替身组，则直接删除不该显示的替身，留下第一个就可以了
        if (stepGroup.userData.TYPE && stepGroup.userData.TYPE == 'GROUP') {

            if (stepGroup.userData.isStandInGroup) {

                var standINARR = []
                for (let i = 0; i < stepGroup.children.length; i++) {
                    if (i > 0) {
                        standINARR.push(stepGroup.children[i])
                    }
                }
                //移除不需要的替身
                for (let i = 0; i < standINARR.length; i++) {
                    standINARR[i].removeFromParent()
                }

            } else {

                findChildren(stepGroup)

            }

        }




        console.log('StandInArreee', StandInArr, StandInArr.length)


        //移除不该存在的替身
        for (let i = 0; i < StandInArr.length; i++) {
            StandInArr[i].removeFromParent()
        }



        // if (stepGroup.userData.isStandInGroup) {
        //     console.log('当前的组是替身组', stepGroup)
        // }


        // //替身组内设置替身可见性
        // if (stepGroup.userData.TYPE == 'GROUP' && stepGroup.userData.isStandInGroup) {
        //     this.setStandInVisibleInStep(stepGroup)
        // }
    }

    //如果是替身组，则将其理解成是一个零件
    //替身组内的第一个零件将会被设为零件图
    // setStandInVisibleInStep(GROup) {

    //     console.log('设置替身组的可见性223332', GROup)

    //     console.log(GROup.children.length)

    //     var noUse = []

    //     for (let i = 0; i < GROup.children.length; i++) {

    //         if (i == 0) {

    //             GROup.children[i].visible = true

    //         } else {
    //             noUse.push(GROup.children[i])
    //         }
    //     }

    //     for (let i = 0; i < noUse.length; i++) {
    //         console.log('移除替身崴', noUse[i])
    //         noUse[i].removeFromParent()
    //     }

    //     console.log(GROup.children.length)


    // }

    async saveIMageToIndexedDB(FileName, imageData, objectStore) {

        return new Promise((resolve) => {
            // let request = window.indexedDB.open("YourDatabaseName", 1);
            // request.onerror = function (event) {
            //     console.error('event4445', event);
            // };
            // request.onsuccess = function (event) {
            // let db = event.target.result;


            // console.log('event445555', event)

            // var objectStore

            // let transaction = db.transaction(["images"], "readwrite");
            // objectStore = transaction.objectStore("images");

            // 将Blob对象作为一个新的记录插入数据库
            let requestAdd = objectStore.put({ key: FileName, imageData });
            requestAdd.onerror = function (event) {
                console.error('事务失败', event);
            };

            requestAdd.onsuccess = function (event) {
                let result = event.target.result;
                console.log('主键保存结果: ', event);
                resolve(result);
            };
            // };

            // request.onupgradeneeded = function (event) {
            //     let db = event.target.result;
            //     db.createObjectStore("images", { keyPath: "key" });
            // };

        });
    }

    async getImageFromIndexedDB(keyImage, objectStore) {


        return new Promise((resolve) => {

            let getRequest = objectStore.get(keyImage);
            getRequest.onerror = function (event) {
                console.log('事务失败', event);
            };

            getRequest.onsuccess = function (event) {
                let result = event.target.result;
                console.log('主键查询结果: ', result);
                resolve(result);
            };

        });
    }

    //创建数据获取器
    async createDB() {

        return new Promise((resolve) => {
            let request = window.indexedDB.open("YourDatabaseName", 1);
            request.onerror = function (event) {
                console.error('event4445', event);
            };
            request.onupgradeneeded = function (event) {
                let db = event.target.result;
                // 在这里创建对象存储，仅当数据库版本变化时执行
                if (!db.objectStoreNames.contains("images")) {
                    db.createObjectStore("images", { keyPath: "key" });
                }
            };
            request.onsuccess = function (event) {
                let db = event.target.result;
                let transaction = db.transaction(["images"], "readwrite");
                let objectStore = transaction.objectStore("images");

                resolve(objectStore);

                // let getRequest = objectStore.get(keyImage);
                // getRequest.onerror = function (event) {
                //     console.log('事务失败', event);
                // };

                // getRequest.onsuccess = function (event) {
                //     let result = event.target.result;
                //     console.log('主键查询结果: ', result);
                //     resolve(result);
                // };
            };
        })
    }

    // let transaction = db.transaction(["images"], "readonly");
    // let objectStore = transaction.objectStore("images");


    // let request = window.indexedDB.open("YourDatabaseName", 1);
    // request.onerror = function (event) {
    //     console.error('event4445', event);
    // };
    // request.onupgradeneeded = function (event) {
    //     let db = event.target.result;
    //     // 在这里创建对象存储，仅当数据库版本变化时执行
    //     if (!db.objectStoreNames.contains("images")) {
    //         db.createObjectStore("images", { keyPath: "key" });
    //     }
    // };
    // request.onsuccess = function (event) {
    //     let db = event.target.result;
    //     let transaction = db.transaction(["images"], "readonly");
    //     let objectStore = transaction.objectStore("images");

    //     let getRequest = objectStore.get(keyImage);
    //     getRequest.onerror = function (event) {
    //         console.log('事务失败', event);
    //     };

    //     getRequest.onsuccess = function (event) {
    //         let result = event.target.result;
    //         console.log('主键查询结果: ', result);
    //         resolve(result);
    //     };
    // };




    // async getImageFromIndexedDB(keyImage) {
    //     return new Promise((resolve) => {
    //         let request = window.indexedDB.open("YourDatabaseName", 1);
    //         request.onerror = function (event) {
    //             console.error('event4445', event);
    //         };
    //         request.onupgradeneeded = function (event) {
    //             let db = event.target.result;
    //             // 在这里创建对象存储，仅当数据库版本变化时执行
    //             if (!db.objectStoreNames.contains("images")) {
    //                 db.createObjectStore("images", { keyPath: "key" });
    //             }
    //         };
    //         request.onsuccess = function (event) {
    //             let db = event.target.result;
    //             let transaction = db.transaction(["images"], "readonly");
    //             let objectStore = transaction.objectStore("images");

    //             let getRequest = objectStore.get(keyImage);
    //             getRequest.onerror = function (event) {
    //                 console.log('事务失败', event);
    //             };

    //             getRequest.onsuccess = function (event) {
    //                 let result = event.target.result;
    //                 console.log('主键查询结果: ', result);
    //                 resolve(result);
    //             };
    //         };
    //     });
    // }



    //     getDataByKey(db, storeName, key) {
    //     let transaction = db.transaction([storeName]) // 事务
    //     let objectStore = transaction.objectStore(storeName) // 仓库对象
    //     let request = objectStore.get(key)

    //     request.onerror = function (event) {
    //         console.log('事务失败')
    //     }

    //     request.onsuccess = function (event) {
    //         console.log('主键查询结果: ', request.result)
    //     }
    // }

    // CreatePartListImagesArr__(Group, ChoosePartIDArr) {

    //     console.log('111Group, ChoosePartIDArr111', Group, ChoosePartIDArr)

    //     // _scene.environment = _pmremGenerator.fromScene(new RoomEnvironment()).texture;

    //     //移除上一次的场景零件
    //     if (stepGroup) {

    //         _scene.remove(stepGroup);

    //     }

    //     _partlist = []
    //     this.CreateStepImagesArrFromGroup(Group, ChoosePartIDArr)


    //     //移除上一次的场景零件
    //     if (stepGroup) {

    //         _scene.remove(stepGroup);

    //     }


    //     return _partlist


    // }


    // CreateStepImagesArrFromGroup(GroupArr) {

    //     // if (1 == 2) { return }

    //     console.log('GroupArr', GroupArr)

    //     for (let i in GroupArr) {

    //         //软零件不需要软零件
    //         //软零件太大会导致零件的图片被缩小
    //         // if(group.children[i].userData.isSoftPart){
    //         //     continue
    //         // }
    //         // if(group.children[i].userData.isSoftPart){
    //         //     group.children[i].traverse(c=>{
    //         //         if(c){

    //         //         }
    //         //     })
    //         // }
    //         //如果选择的零件在ChoosePartIDArr中则显示
    //         // if (ChoosePartIDArr.indexOf(group.children[i].id) !== -1) {

    //             // if (ChoosePartIDArr.indexOf(group.children[i].id) == 10000000) {

    //             stepGroup = new THREE.Group()
    //             _scene.add(stepGroup)
    //             stepGroup.rotation.set(Math.PI, 0, 0)

    //             stepGroup.position.set(0, 0, 0)

    //             var isGROUP = false
    //             //名字不包含dat或者ldr且type为Model
    //             if ((!(group.children[i].name.indexOf('.dat') !== -1 || group.children[i].name.indexOf('.ldr') !== -1))
    //                 && (group.children[i].userData.type == 'Model')) {

    //                 isGROUP = true

    //             }

    //             //生成临时图片链接
    //             var partID
    //             var partEmpty = false
    //             console.log('stepful[i]', group.children[i])
    //             if (group.children[i].children.length == 0) {

    //                 partEmpty = true

    //             }

    //             partModel = GroupArr[i].clone()
    //             partModel.visible = true

    //             // var stepNum = group.children[i].userData.ThisFloorStepNum + 1

    //             partID = group.children[i].id

    //             stepGroup.add(partModel)

    //             this.hideAllBox3Helper(stepGroup)

    //             partModel.rotation.set(0, 0, 0)

    //             /***软零件克隆逻辑，需要重新绑定骨骼并且根据userdata还原其变形******下面代码***************/
    //             //克隆完成后，遍历结果并将软零件的数据设置上去

    //             //软零件需要重新制作骨骼，并重新绑定骨骼

    //             //重新赋予和绑定新骨骼
    //             // for (let i in softPartArr) {
    //             //     this.CopySoftPartSet(softPartArr[i])
    //             //     // softPartArr[i].updateWorldMatrix(true,true)
    //             // }
    //             /***软零件克隆逻辑，需要重新绑定骨骼并且根据userdata还原其变形****上面代码*****************/


    //             const imgURL = this.greateTempImgURL(stepGroup)

    //             let partEnglishName = ''

    //             if (partModel.name) {

    //                 partEnglishName = partModel.name

    //             } else {
    //                 partEnglishName = 'noneName'
    //             }

    //             _partlist.push(
    //                 {
    //                     url: imgURL,
    //                     partName: partEnglishName,
    //                     partID,
    //                     partEmpty,
    //                     isGROUP,
    //                     stepNum,
    //                     parentId: group.id
    //                 }
    //             )

    //             stepGroup.removeFromParent()

    //             // i++
    //         // }
    //     }


    //     console.log('imgURLArr', _partlist)
    // }

    /**
     * 隐藏所有包围盒
     */
    hideAllBox3Helper(Group) {

        Group.traverse(c => {

            if (c.isLine) {

                if (c.name == "MyBoxHelper") {

                    c.visible = false


                }
            }
        })



    }

    //找到子孙中与本步骤相等的步骤
    //如果组是替身组，则不找后代
    findAllSameGlobalPartsArr(GroupArr_) {

        const GlobalStepNum = GroupArr_[0].userData.MyStep


        var PartsArr = []


        const findChildren = function (Group, partIsStandInGroup = false) {

            for (let i = 0; i < Group.children.length; i++) {

                if (Group.children[i].userData.TYPE) {

                    if (Group.children[i].userData.TYPE == 'GROUP') {

                        console.log('保存组eeee', Group.children[i])

                        if (Group.children[i].userData.MyStep == GlobalStepNum) {

                            console.log('保存组', Group.children[i])

                            PartsArr.push(Group.children[i])

                        }

                        //祖上不是替身组才会往下找
                        if (!partIsStandInGroup) {

                            findChildren(Group.children[i], false)

                        } else if (!Group.children[i].userData.isStandInGroup) {
                            //如果是替身组，则不用往下找了
                            //不是替身组才会往下找

                            findChildren(Group.children[i], false)

                        }




                    } else if (Group.children[i].userData.TYPE == 'PART') {

                        if (Group.children[i].userData.MyStep == GlobalStepNum) {

                            PartsArr.push(Group.children[i])

                        }

                    }

                }

            }


        }

        for (let j = 0; j < GroupArr_.length; j++) {

            if (GroupArr_[j].userData.TYPE == 'GROUP') {

                PartsArr.push(GroupArr_[j])
                // partIsStandInGroup


                //看看是不是替身组，不是替身组才会往里面找
                //不是替身组才会往下找
                if (!GroupArr_[j].userData.isStandInGroup) {

                    findChildren(GroupArr_[j], false)
                }

            } else if (GroupArr_[j].userData.TYPE == 'PART') {

                PartsArr.push(GroupArr_[j])

            }

        }

        return PartsArr

    }

    //判断是否有相同型号和颜色的零件在图片组中
    samePartInImage_arr(_partlist, GroupArr_i) {

        var inImage = false

        var sameImage = null
        // id: GroupArr[i].id,
        // url: resImagePart,
        // partname: stepGroup.userData.fileName,
        // colorCode: stepGroup.userData.colorCode,
        for (let j = 0; j < _partlist.length; j++) {

            if (_partlist[j].partname == GroupArr_i.userData.fileName) {

                if (_partlist[j].colorCode == GroupArr_i.userData.colorCode) {

                    inImage = true

                    sameImage = _partlist[j]

                    break
                }

            }

        }

        if (inImage) {

            return sameImage

        }

        // return inImage

    }

    /**
    * 带骨骼的软零件被复制或者克隆后，软零件的骨骼并没有被复制，克隆的零件和被克隆体共用了骨骼组，我们需要被克隆的对象有新的骨骼组
    * 这个操作可以让被克隆的组具有新的独立的骨骼，并且新骨骼会被设置为被克隆时的样子
    * 需要用到软零件在最初生成时在mesh的userdata中的初始数据 ：userData.bonesInitData
    */
    // CopySoftPartSet(softPart) {

    //     const oldBones = softPart.children[0].skeleton.bones
    //     const newBones = this.CreateBonesFromData(softPart.children[0].userData.bonesInitData)
    //     const skeleton = new THREE.Skeleton(newBones);

    //     if (softPart.children[0].children.length > 0) {
    //         softPart.children[0].children[0].removeFromParent()
    //     }

    //     softPart.children[0].add(newBones[0]);

    //     softPart.children[0].bind(skeleton);

    //     //为mesh备份所有骨骼节点的数据（位置和旋转），方便以后需要的时候还原

    //     //将旧骨骼的数据还原到新骨骼上去
    //     for (let i = 0; i < oldBones.length; i++) {
    //         newBones[i].position.copy(oldBones[i].position)
    //         newBones[i].rotation.copy(oldBones[i].rotation)
    //     }
    //     // skeleton.update()
    // }

    /**
    *根据mesh的userdata中的骨骼数据，重新生成新骨骼组
    */
    // CreateBonesFromData(boneData) {
    //     var bones = [];
    //     let prevBone = new THREE.Bone();
    //     bones.push(prevBone);
    //     // prevBone.position.z = - sizing.halfHeight;
    //     // prevBone.position.z = 0;
    //     prevBone.position.copy(boneData[0].position)
    //     prevBone.rotation.copy(boneData[0].rotation)

    //     for (let i = 0; i < boneData.length; i++) {
    //         const bone = new THREE.Bone();
    //         // bone.position.z = 1;
    //         bone.position.copy(boneData[i].position)
    //         bone.rotation.copy(boneData[i].rotation)
    //         bones.push(bone);
    //         prevBone.add(bone);
    //         prevBone = bone;
    //     }

    //     return bones;
    // }



    //传入零件模型，渲染出零件临时图片
    greateTempImgURL(part_group) {

        part_group.updateWorldMatrix(true, true)

        console.log('part_group111', part_group)

        // part_box3.expandByObject(part_group)
        // let part_center = new THREE.Vector3();
        // part_box3.getCenter(part_center)

        let cameraLookAt = new THREE.Vector3()
        // const bbox = new THREE.Box3().setFromObject(part_group);
        const bbox = new THREE.Box3()


        part_group.traverse((c) => {

            //先粗暴的搞，其实还是要结合步骤可见性设置等进行设置的
            if (c.isGroup) {

                c.visible = true

            }


            if (c.isMesh) {
                // if(!c.userData.isSoftPart){
                bbox.expandByObject(c);
                // }
            }
        });


        const size = bbox.getSize(new THREE.Vector3());
        bbox.getCenter(cameraLookAt)

        const radius = Math.max(size.x, Math.max(size.y, size.z)) * 0.5;


        const fieldOfView = 8; // 设置视野角度为60度，可以根据实际情况调整
        //* 1.2 这是个系数
        const distance = (radius / 2) * 1.4 / Math.tan((Math.PI / 180) * fieldOfView / 2) * 2;

        //拍摄角度
        //水平角度
        const horizontalAngle = 135
        //垂直角度
        const verticalAngle = -45


        const cameraPosition = {
            x: cameraLookAt.x - distance * Math.sin((Math.PI / 180) * horizontalAngle),
            y: cameraLookAt.y - distance * Math.sin((Math.PI / 180) * verticalAngle),
            z: cameraLookAt.z - distance * Math.cos((Math.PI / 180) * horizontalAngle),
        };
        _camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);
        _camera.lookAt(cameraLookAt);

        console.log('渲染零件参数wwww', cameraPosition, cameraLookAt)


        // let imageurl = ''
        // let Blob

        // 设置渲染结果(一帧图像)的像素为500x500

        // 设置特定target的时候，render渲染结果不会显示在canvas画布上
        // imageurl = canvas.toDataURL()

        //执行渲染操作
        console.log('_sceneeee', _scene)
        // _scene.traverse(c=>{
        //     if(c){

        //         console.log('不正常',c)

        //     }
        // })
        _renderer.render(_scene, _camera);


        part_group.removeFromParent()

        var canvas = _renderer.domElement; //获取canvas对象

        const imageurl = canvas.toDataURL()


        // Blob = canvas.toBlob()

        // Blob = await this.convertCanvasToBlob()

        // console.log('Blob33', Blob)


        return imageurl


    }

    //清空
    disposeData() {



    }

    // async convertCanvasToBlob() {
    //     var canvas = _renderer.domElement; // 获取canvas对象

    //     // 创建一个Promise来处理异步操作
    //     return new Promise((resolve, reject) => {
    //         if (!canvas || !canvas.toBlob) {
    //             reject(new Error('无法获取或转换canvas到Blob'));
    //             return;
    //         }

    //         canvas.toBlob(blob => {
    //             if (blob) {
    //                 resolve(blob);
    //                 console.log('Blob33', blob);
    //             } else {
    //                 reject(new Error('未能成功生成Blob对象'));
    //             }
    //         }, 'image/png'); // 指定MIME类型为PNG
    //     });
    // }


}
// BottomPartListCreater.js
export { MpdPartListCreater };