<template>
  <div style="width: 100vw;height: 100vh;">
    <!-- <div>文件查看器</div>
    <div>{{ FilePlayerData }}</div> -->

    <VideoPlayerView></VideoPlayerView>


    <FileImage></FileImage>


    <wppsPlayer></wppsPlayer>


    <ImagePlayer></ImagePlayer>


    <MpdPlayer></MpdPlayer>

  </div>

</template>

<script>
import ImagePlayer from '@/components/ImagePlayer.vue'
import wppsPlayer from '@/components/wppsPlayer.vue'
import VideoPlayerView from '@/components/VideoPlayer.vue'
import FileImage from '@/components/FileImage.vue'
import MpdPlayer from "@/components/MpdPlayer.vue"
import router from '@/router'
export default {
  data() {
    return {

    }
  },

  components: {
    VideoPlayerView,
    FileImage,
    wppsPlayer,
    ImagePlayer,
    MpdPlayer,
  },

  computed: {
    FilePlayerData() {
      return this.$store.getters.getFilePlayerData;
    },
  },

  mounted() {
    // 在组件挂载文件后启动vuex变量（查看文件的变量）监视器
    // this.startWatching();

    console.log('文件查看器启动111')
    console.log('3333', this.$route.params.data); // 打印从URL获取的id参数
    this.openFile()



  },

  watch: {
    '$route'(to, from) {
      console.log('to22, from22', to, from)
      // 检测是否跳入了当前页面
      if (to.path === '/FilePlayer') {
        console.log('跳入了页面 FilePlayer');
        console.log('3333', this.$route.params.data);
        this.openFile()
        // 在这里执行你的逻辑
      }
    }
  },


  methods: {

    //打开文件
    openFile() {
      //看看文件播放器的vuex变量是多少
      const FilePlayerData = this.FilePlayerData

      console.log('FilePlayerData', this.FilePlayerData)

      const CreatorID = FilePlayerData.CreatorID
      const schoolName = FilePlayerData.schoolName
      const shareTeacher = FilePlayerData.shareTeacher

      const temporary_key = FilePlayerData.temporary_key

      console.log('打开文件时获取到的数据是', FilePlayerData)

      if (FilePlayerData.type == 'mp4' || FilePlayerData.type == 'avi') {

        this.$emit("messageToVideoPlayer", {
          type: "putKey",
          Key: FilePlayerData.Key,
          temporary_key,




        });

      } else if (FilePlayerData.type == 'docx' || FilePlayerData.type == 'pptx' || FilePlayerData.type == 'pdf' || FilePlayerData.type == 'xlsx') {
        //wpps

        console.log('文件播放器即将打开wpps发消息', FilePlayerData)



        this.$emit("messageToWppsPlayer", {
          type: "putKey",
          Key: FilePlayerData.Key,
          temporary_key,
          CreatorID,
          schoolName,
          shareTeacher,
          bucket: FilePlayerData.bucket,
          region: FilePlayerData.region

        });

        console.log('文件播放器即将打开wpps发消息2', FilePlayerData)


        // this.$emit("messageToVideoPlayer", {
        //   type: "putKey",
        //   Key: FilePlayerData.Key,
        //   temporary_key
        // });


      } else if (FilePlayerData.type == 'jpg' || FilePlayerData.type == 'jpeg' || FilePlayerData.type == 'png' || FilePlayerData.type == 'gif') {

        this.$emit("messageToImagePlayer", {
          type: "putKey",
          Key: FilePlayerData.Key,
          temporary_key


        });

      } else if (FilePlayerData.type == 'mpd') {

        this.$emit("messageToMpdPlayer", {
          type: "putKey",
          Key: FilePlayerData.Key,
          temporary_key,
          CreatorID,
          schoolName,
          shareTeacher,
        });

        // messageToMpdPlayer
      } else if (FilePlayerData.type == 'ldr') {

        this.$message({
          message: 'ldr文件转换成mpd文件后才能打开',
          type: 'warning'
        });

        setTimeout(() => {

          router.back()

        }, 500);
        // messageToMpdPlayer
      } else {
        // this.$root.$MyCloudFiles.getLietByTags(FilePlayerData.path)
        // getLietByTags
        this.$message({
          message: '不支持打开此格式文件:' + FilePlayerData.type + '>>自动返回上一页',
          type: 'warning'
        });

        setTimeout(() => {

          router.back()

        }, 500);
      }















    },



    // startWatching() {
    //   this.$watch(() => this.$store.state.FilePlayerData, (newVal, oldVal) => {
    //     console.log(`vuex变量变化Count changed from ${oldVal} to ${newVal}`);
    //     console.log('oldVal', oldVal)
    //     console.log('newVal', newVal)

    //     const CreatorID = newVal.CreatorID
    //     const schoolName = newVal.schoolName
    //     const shareTeacher = newVal.shareTeacher

    //     const temporary_key = newVal.temporary_key

    //     console.log('打开文件时获取到的数据是', newVal)

    //     if (newVal.type == 'mp4' || newVal.type == 'avi') {

    //       this.$emit("messageToVideoPlayer", {
    //         type: "putKey",
    //         Key: newVal.Key,
    //         temporary_key,




    //       });

    //     } else if (newVal.type == 'docx' || newVal.type == 'pptx' || newVal.type == 'pdf' || newVal.type == 'xlsx') {
    //       //wpps

    //       console.log('文件播放器即将打开wpps发消息', newVal)



    //       this.$emit("messageToWppsPlayer", {
    //         type: "putKey",
    //         Key: newVal.Key,
    //         temporary_key,
    //         CreatorID,
    //         schoolName,
    //         shareTeacher,
    //         bucket: newVal.bucket,
    //         region: newVal.region

    //       });

    //       console.log('文件播放器即将打开wpps发消息2', newVal)


    //       // this.$emit("messageToVideoPlayer", {
    //       //   type: "putKey",
    //       //   Key: newVal.Key,
    //       //   temporary_key
    //       // });


    //     } else if (newVal.type == 'jpg' || newVal.type == 'jpeg' || newVal.type == 'png' || newVal.type == 'gif') {

    //       this.$emit("messageToImagePlayer", {
    //         type: "putKey",
    //         Key: newVal.Key,
    //         temporary_key


    //       });

    //     } else if (newVal.type == 'mpd') {

    //       this.$emit("messageToMpdPlayer", {
    //         type: "putKey",
    //         Key: newVal.Key,
    //         temporary_key,
    //         CreatorID,
    //         schoolName,
    //         shareTeacher,
    //       });

    //       // messageToMpdPlayer
    //     } else if (newVal.type == 'ldr') {

    //       this.$message({
    //         message: 'ldr文件转换成mpd文件后才能打开',
    //         type: 'warning'
    //       });
    //       // messageToMpdPlayer
    //     } else {
    //       // this.$root.$MyCloudFiles.getLietByTags(newVal.path)
    //       // getLietByTags
    //       this.$message({
    //         message: '不支持打开此格式文件:' + newVal.type,
    //         type: 'warning'
    //       });
    //     }





    //   });
    // },


  }
}

</script>

<style></style>